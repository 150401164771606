import React, { useRef, useCallback } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch } from 'components';
import { PERSONAL_TRAINER,WORK_PLACE } from 'constants/index';

import { Text, Svg, Material, Box, Button, Modal } from 'components';
import {
  setGroupSelectionData,
  createSeance,
} from 'actions';

export default function GroupRightSelections({
  setTab = () => {},
  setTabPage = () => {},
}) {
  const {reservation } = useSelector(
    (state) => state.profileSettings2.reservationGroupSlot
  );
  const { type_id: userTypeId } = useSelector((state) => state.auth.user);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reservationSuccessModalRef = useRef();
  const selectDataHandler = (name, value) =>
    dispatch(setGroupSelectionData(name, value));
  const closeSuccessReservationModal = useCallback(() => {
    reservationSuccessModalRef.current.closeModal();
    setTabPage('');
    setTab('Calendar');
  }, []);

  const createPacketHandler = () => {
    var type;
    if(userTypeId == WORK_PLACE) type='gym';
    if(userTypeId == PERSONAL_TRAINER) type='pt';

    dispatch(
      createSeance(type,() => reservationSuccessModalRef.current.openModal())
    );
  };

  return (
    <RightWrapper>
      <RightFooter>
      {
          userTypeId !==WORK_PLACE && <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <text style={{ fontFamily: 'Poppins' }}>{t('Reservation')}</text>
          <Switch
            checked={reservation}
            onChange={() => selectDataHandler('reservation', !reservation)}

          />
        </div>
      }
       {reservation &&  <>
          <DarkTitle>{t('Determine the Number of Sessions')}</DarkTitle>
          <Material.TextField
            onChange={(e) => selectDataHandler('seanceCount', e.target.value)}
            label={t('enter')}
            type="number"
          />
        </>}

        <DarkTitle style={{ marginTop: '5px' }}>{t('Set Price')}</DarkTitle>
        <>
          <Material.TextField
            onChange={(e) => selectDataHandler('seancePrice', e.target.value)}
            error={false}
            label={t('enter')}
            type="number"
          />
        </>

        <Button
          onClick={createPacketHandler}
          text={t('complete')}
          className="blue"
          width="100%"
          height="65px"
          mt="15px"
        />
      </RightFooter>

      <SuccessModal activateFooter ref={reservationSuccessModalRef}>
        <Box center my="30px">
          <Svg.SuccessIcon />
        </Box>

        <Text fontWeight="600" fontSize="1.1rem" textAlign="center">
          {t('Congratulations')}
        </Text>

        <Text textAlign="center" mb="30px">
          {t('Your Package Session has been created')}
        </Text>

        <Modal.Footer>
          <Text
            textAlign="center"
            color="blue"
            p="0"
            cursor="pointer"
            onClick={closeSuccessReservationModal}
          >
            {t('SEE MY BOOKING CALENDAR')}
          </Text>

          <Link to="/" className="group-right-selections__homepage">
            {t('HOME PAGE')}
          </Link>
        </Modal.Footer>
      </SuccessModal>
    </RightWrapper>
  );
}

const SuccessModal = styled(Modal)`
  .modal-content {
    width: 500px;
  }

  .group-right-selections {
    &__homepage {
      border-top: 1px solid rgba(144, 144, 144, 0.2);
      text-align: center;
      padding-top: 20px;
      cursor: pointer;
      color: ${(p) => p.theme.colors.dark};
      display: block;
      margin-top: 20px;
    }
  }
`;

const RightWrapper = styled.div`
  border-radius: 20px;
  background: #f8f8f8;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.14);
  overflow: hidden;
`;

const RightFooter = styled.div`
  background-color: white;
  padding: 30px;
`;

const DarkTitle = styled.h4`
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  color: ${(p) => p.theme.colors.dark};
`;
