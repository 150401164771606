import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import SelectPictureModal from './SelectPictureModal';
import { Svg, Text, Box, PlusButton, Material } from 'components';
import { PERSONAL_TRAINER, DIETITIAN,WORK_PLACE } from 'constants/index';
import {
  getSessionTypes,
  setGroupSelectionData,
  getGroupImages,
  getUserPTBranchList,
  getDietitianClinics,
  getPtWorkingHomePlace,
  getDayOfCalendar,
  getFilteredGymList,
  getPrivatePackageData
} from 'actions';
import { useTranslation } from 'react-i18next';

export default function GroupLeftSelections() {
  const { t } = useTranslation();

  const { type_id: userTypeId } = useSelector((state) => state.auth.user);
  const privateUserData = useSelector((state) => state.privatePackage.user);


  const {
    groupImages: { data: groupImages },
    branchSelection,
    dtSessionSelection,
    selectedDate,
  } = useSelector((state) => state.profileSettings2.reservationGroupSlot);

  const { get: sessionTypes } = useSelector(
    (state) => state.profileSettings2.sessionType
  );

  useEffect(() => {
    dispatch(getDayOfCalendar(moment(selectedDate).format('DD.MM.YYYY')));
  }, [selectedDate]);


  const [selectedImageId, setSelectedImageId] = useState();
  const [file, setFile] = useState();

  const selectPicModalRef = useRef();

  const dispatch = useDispatch();

  const openSelectPicModal = () => selectPicModalRef.current.openModal();

  useEffect(() => {
    if (userTypeId === PERSONAL_TRAINER) {
      dispatch(getSessionTypes());
      dispatch(getPtWorkingHomePlace());
      dispatch(getUserPTBranchList());
      dispatch(getGroupImages());
      dispatch(getFilteredGymList());
      dispatch(getPrivatePackageData('pt'))
    }
    if (userTypeId === WORK_PLACE) {
      dispatch(getGroupImages());
      dispatch(getPrivatePackageData('gym'))

    }
    if (userTypeId === DIETITIAN) {
      dispatch(getSessionTypes());
      dispatch(getDietitianClinics());
      dispatch(getGroupImages());
      dispatch(getPrivatePackageData('dt'))

    }
  }, []);

  
  useEffect(() => {
    if (selectedImageId) {
      selectDataHandler('group_slot_image_id', selectedImageId?.id);
    } else if (file) {
      // const resizedFile = await resizeFile(file);

      selectDataHandler('group_slot_image', file);
    }
  }, [selectedImageId, file]);


  const selectDataHandler = (name, value) =>
    dispatch(setGroupSelectionData(name, value));

  return (
    <div>
      <Box row justifyContent="center" position="relative">
        <UploadPic
          onClick={openSelectPicModal}
          img={selectedImageId && selectedImageId?.image_url}
        >
          {!selectedImageId && <Svg.MockImageIcon />}
          {!selectedImageId && (
            <Text textAlign="center" color="gray8" fontWeight="300" mt="15px">
              {t('SELECT PHOTO')}
            </Text>
          )}
        </UploadPic>

        <Plus type="dark" onClick={openSelectPicModal} />
      </Box>

      <>
        <FormControl className="w-100 mt-2">
          <Material.TextField
            label={t('Enter title')}
            name="title"
            required
            //value={data.blog.title}
            onChange={(e) => selectDataHandler('title', e.target.value)}
          />
        </FormControl>
      </>

      <Text color="gray10" fontWeight="600" fontSize="1.1rem" mt="20px">
        {t('Enter Package Contents')}
      </Text>

      <TextArea
        rows={6}
        onBlur={(e) => selectDataHandler('courseDetails', e.target.value)}
      />
      <>
        <FormControl className="w-100 mt-2">
          <InputLabel>{t('Select Branch')}</InputLabel>

          <Select
            value={branchSelection}
            input={<Input />}
            onChange={(e) =>
              selectDataHandler('branchSelection', e.target.value)
            }
          >
            {privateUserData?.branches?.length > 0 &&
              privateUserData?.branches.map(
                (branch) =>
              
                    <MenuItem key={branch.id} value={branch}>
                      {branch.name}
                    </MenuItem>
                  
              )}
          </Select>
        </FormControl>
      </>
     { userTypeId !==WORK_PLACE &&  <FormControl className="w-100 mt-2">
        <InputLabel>{t('Select Session Types')}</InputLabel>

        <Select
          multiple
          value={dtSessionSelection}
          input={<Input />}
          onChange={(e) =>
            selectDataHandler('dtSessionSelection', e.target.value)
          }
        >
          {sessionTypes?.data?.data?.map((sessionType) => (
            <MenuItem key={sessionType.id} value={sessionType}>
              {sessionType.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>}

      <SelectPictureModal
        ref={selectPicModalRef}
        selectedImageId={selectedImageId}
        setSelectedImageId={setSelectedImageId}
        file={file}
        setFile={setFile}
        images={groupImages}
      />
    </div>
  );
}

const Plus = styled(PlusButton)`
  position: absolute;
  bottom: -27px;
  left: 50%;
  transform: translate(-50%);
`;

const TextArea = styled.textarea`
  padding: 15px;
  border-radius: 15px;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 10px;

  &::placeholder {
    font-size: 0.9rem;
  }
`;

const UploadPic = styled.div`
  width: 250px;
  height: 200px;
  border-radius: 8px;
  background: '${(props) => !props.img && '#fff'}';
  border: 1px solid #363636;
  padding: 45px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  cursor: pointer;
  background-image: url('${(props) => props.img}');
  background-repeat: '${(props) => props.img && 'no-repeat'}';
  background-size: cover;
`;
