import React from 'react';

import Home from './Home';

const DT = () => {

  return (
    <Home />
  );
};

export default DT;
