// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button, Material } from '../../../components';

import { useSelector, useDispatch } from 'react-redux';
import { setStepFourQuestions } from '../../../actions';
import { CityDistrictSelector } from 'components';
const StepFour = (props) => {
  const { t } = useTranslation();

  const { setSteps } = props;
  const dispatch = useDispatch();


  const getStepFour = useSelector((state) => state.stepFour);
  const [selectedType, setSelectedType] = useState(null);
  const [city, setCity] = useState(null);
  const [district, setDistrict] = useState(null);
  const [priceRange, setPriceRange] = useState([]);
  const [sessionTypes, setSessionTypes] = useState([]);

  useEffect(()=>{
    setCity(null)
    setDistrict(null)
    setPriceRange(null)
    setSessionTypes([])
  },[selectedType])
  const succsess = () => {
    toast.success(t(`Question answers have been sent`), {
      position: 'bottom-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    setTimeout(() => {
      toast.info(t('Please wait! You are redirected...'), {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => setSteps('finish'),
      });
    }, 1000);
  };

  const err = () => {
    toast.error(t(`Question answers could not be sent!`), {
      position: 'bottom-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    switch (selectedType) {
      case 'pt':
        return dispatch(
          setStepFourQuestions(
            {
              user_type: [selectedType],
              city_id: city,
              district_id: district,
              session_type: sessionTypes,
              price_range: priceRange,
            },
            succsess,
            err
          )
        );
      case 'dt':
        return dispatch(
          setStepFourQuestions(
            {
              user_type: [selectedType],
              session_type: sessionTypes,
              price_range: priceRange,

            },
            succsess,
            err
          )
        );
      case 'bs':
        return dispatch(
          setStepFourQuestions(
            {
              user_type: [selectedType],
              city_id: city,
              district_id: district,
              price_range: priceRange,
            },
            succsess,
            err
          )
        );

      default:
        break;
    }
  };

  return (
    <>
      <Text>
        *
        {t(
          'For your health, we ask you to take 5 minutes of your time and solve our test'
        )}
      </Text>
      <form onSubmit={onSubmit} autoComplete="off">
        {/* <Material.CheckBoxGroup
          style={{ color: 'red' }}
          name={'test2'}
          label={'item.namesdssd'}
          onChange={(e) => {
            // _survey_id(val.survey_id);
            // _question([...question, val.id]);
            // _answer({
            //   ...answer,
            //   [e.target.name]: [item.id],
            // });
          }}
        />  */}
        <h6 style={{ marginBottom: '10px' }}>Ne Arıyorsun ? </h6>
        <Material.select
          required
          name="userType"
          forHtml="userType"
          label={'Profesyonel Tipi'}
          onChange={(e) => setSelectedType(e.target.value)}
          items={[
            { id: 'pt', name: 'Eğitmen' },
            { id: 'dt', name: 'Diyetisyen' },
            { id: 'bs', name: 'Spor Salonu' },
          ]}
        />
        {/* <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            padding: '0 0 0 20px',
          }}
        >
          <Material.CheckBoxGroup
            style={{ color: 'red' }}
            name={'pt'}
            label={'Eğitmen'}
            onChange={(e) => {
              let type = 'pt';
              if (e.target.checked) {
                setSelectedType((state) => [...state, type]);
              } else {
                setSelectedType((state) => [
                  ...state.filter((e) => e !== type),
                ]);
              }
            }}
          />
          <Material.CheckBoxGroup
            style={{ color: 'red' }}
            name={'bs'}
            label={'Salon'}
            onChange={(e) => {
              let type = 'bs';
              if (e.target.checked) {
                setSelectedType((state) => [...state, type]);
              } else {
                setSelectedType((state) => [
                  ...state.filter((e) => e !== type),
                ]);
              }
            }}
          />
          <Material.CheckBoxGroup
            style={{ color: 'red' }}
            name={'dt'}
            label={'Diyetisyens'}
            onChange={(e) => {
              let type = 'dt';
              if (e.target.checked) {
                setSelectedType((state) => [...state, type]);
              } else {
                setSelectedType((state) => [
                  ...state.filter((e) => e !== type),
                ]);
              }
            }}
          />
         
        </div> */}
        {selectedType == 'pt' && (
          <>
            <h6>Nerede arıyorsunuz?</h6>
            <CityDistrictSelector
              onSelectCity={(val) => {
                setCity(val);
              }}
              onSelectDistrict={(val) => {
                setDistrict(val);
              }}
            />
            <div style={{ paddingLeft: '20px' }}>
              <Material.CheckBoxGroup
                style={{ color: 'red' }}
                name={'online'}
                label={'Online'}
                onChange={(e) => {
                  let type = 'online';
                  if (e.target.checked) {
                    setSessionTypes((state) => [...state, type]);
                  } else {
                    setSessionTypes((state) => [
                      ...state.filter((e) => e !== type),
                    ]);
                  }
                }}
              />
              <Material.CheckBoxGroup
                style={{ color: 'red' }}
                name={'home_park'}
                label={'Ev Park'}
                onChange={(e) => {
                  let type = 'home_park';
                  if (e.target.checked) {
                    setSessionTypes((state) => [...state, type]);
                  } else {
                    setSessionTypes((state) => [
                      ...state.filter((e) => e !== type),
                    ]);
                  }
                }}
              />
              <Material.CheckBoxGroup
                style={{ color: 'red' }}
                name={'bs'}
                label={'GYM'}
                onChange={(e) => {
                  let type = 'bs';
                  if (e.target.checked) {
                    setSessionTypes((state) => [...state, type]);
                  } else {
                    setSessionTypes((state) => [
                      ...state.filter((e) => e !== type),
                    ]);
                  }
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              Fiyat aralıgınız nedir ?
              <Material.RadioButtonsGroup
                required={false}
                name={'test'}
                val={[0, 50]}
                items={[
                  { name: '0-50', value: [0, 50] },
                  { name: '50-100', value: [50, 100] },
                  { name: '100-200', value: [100, 200] },
                ]}
                onChange={(e) => {
                  switch (e.target.value) {
                    case '0-50':
                      setPriceRange([0, 50]);
                      break;
                    case '50-100':
                      setPriceRange([50, 100]);
                      break;
                    case '100-200':
                      setPriceRange([100, 200]);
                      break;

                    default:
                      break;
                  }
                }}
              />
            </div>
          </>
        )}
        {selectedType == 'dt' && (
          <>
            <h6>Derslerinizi nasıl gerçekleştirmek istiyorsunuz?</h6>

            <div style={{ paddingLeft: '20px' }}>
              <Material.CheckBoxGroup
                style={{ color: 'red' }}
                name={'online'}
                label={'Online'}
                onChange={(e) => {
                  let type = 'online';
                  if (e.target.checked) {
                    setSessionTypes((state) => [...state, type]);
                  } else {
                    setSessionTypes((state) => [
                      ...state.filter((e) => e !== type),
                    ]);
                  }
                }}
              />
              <Material.CheckBoxGroup
                style={{ color: 'red' }}
                name={'clinic'}
                label={'Klinik'}
                onChange={(e) => {
                  let type = 'clinic';
                  if (e.target.checked) {
                    setSessionTypes((state) => [...state, type]);
                  } else {
                    setSessionTypes((state) => [
                      ...state.filter((e) => e !== type),
                    ]);
                  }
                }}
              />
             
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              Fiyat aralıgınız nedir ?
              <Material.RadioButtonsGroup
                required={false}
                name={'test'}
                val={[0, 50]}
                items={[
                  { name: '0-50', value: [0, 50] },
                  { name: '50-100', value: [50, 100] },
                  { name: '100-200', value: [100, 200] },
                ]}
                onChange={(e) => {
                  switch (e.target.value) {
                    case '0-50':
                      setPriceRange([0, 50]);
                      break;
                    case '50-100':
                      setPriceRange([50, 100]);
                      break;
                    case '100-200':
                      setPriceRange([100, 200]);
                      break;

                    default:
                      break;
                  }
                }}
              />
                
            </div>
          </>
        )}
        {selectedType == 'bs' && (
          <>
            <h6>Nerede arıyorsunuz?</h6>
            <CityDistrictSelector
              onSelectCity={(val) => {
                setCity(val);
              }}
              onSelectDistrict={(val) => {
                setDistrict(val);
              }}
            />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              Fiyat aralıgınız nedir ?
              <Material.RadioButtonsGroup
                required={false}
                name={'test'}
                val={[0, 50]}
                items={[
                  { name: '0-50', value: [0, 50] },
                  { name: '50-100', value: [50, 100] },
                  { name: '100-200', value: [100, 200] },
                ]}
                onChange={(e) => {
                  switch (e.target.value) {
                    case '0-50':
                      setPriceRange([0, 50]);
                      break;
                    case '50-100':
                      setPriceRange([50, 100]);
                      break;
                    case '100-200':
                      setPriceRange([100, 200]);
                      break;

                    default:
                      break;
                  }
                }}
              />
            </div>
          </>
        )}
       

        {!getStepFour.isLoading || !getStepFour.isSuccess ? (
          <Button
            type="submit"
            text={t('Complete Registration')}
            className="blue"
          />
        ) : (
          <Button text={t('Loading')} className="blue" />
        )}
      </form>
    </>
  );
};

export default StepFour;

const Text = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 11pt;
  color: #181818;
  line-height: 175%;
  color: #00b2a9;
  margin-bottom: 15px;
  /* font-style: italic; */
  /* font-weight: 600; */
`;
