import React from 'react';
import CalendarTemplate from '../Calendar/Calendar'

const Calendar = () => {

  return(
    <CalendarTemplate/>
  );
};


export default Calendar;
