export const HTTP_REQUEST = 'HTTP_REQUEST';

export const USER_DETAILS_SET_FROM_STORAGE = 'USER_DETAILS_SET_FROM_STORAGE';
export const GET_HOME_TAGS = 'GET_HOME_TAGS';
export const GET_HOME_TAGS_REQUEST = 'GET_HOME_TAGS_REQUEST';
export const GET_HOME_TAGS_SUCCESS = 'GET_HOME_TAGS_SUCCESS';
export const GET_HOME_TAGS_FAILURE = 'GET_HOME_TAGS_FAILURE';

export const BLOCK_USER = 'BLOCK_USER';
export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE';

export const UNBLOCK_USER = 'UNBLOCK_USER';
export const UNBLOCK_USER_REQUEST = 'UNBLOCK_USER_REQUEST';
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';
export const UNBLOCK_USER_FAILURE = 'UNBLOCK_USER_FAILURE';

export const GET_BLOCKED_USERS = 'GET_BLOCKED_USERS';
export const GET_BLOCKED_USERS_REQUEST = 'GET_BLOCKED_USERS_REQUEST';
export const GET_BLOCKED_USERS_SUCCESS = 'GET_BLOCKED_USERS_SUCCESS';
export const GET_BLOCKED_USERS_FAILURE = 'GET_BLOCKED_USERS_FAILURE';

export const LOGOUT = 'LOGOUT';
export const GET_STATIC_PAGE = 'GET_STATIC_PAGE';
export const GET_STATIC_PAGE_REQUEST = 'GET_STATIC_PAGE_REQUEST';
export const GET_STATIC_PAGE_SUCCESS = 'GET_STATIC_PAGE_SUCCESS';
export const GET_STATIC_PAGE_FAILURE = 'GET_STATIC_PAGE_FAILURE';

export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const USER_INFORMATION = 'USER_INFORMATION';
export const USER_INFORMATION_REQUEST = 'USER_INFORMATION_REQUEST';
export const USER_INFORMATION_SUCCESS = 'USER_INFORMATION_SUCCESS';
export const USER_INFORMATION_FAILURE = 'USER_INFORMATION_FAILURE';

export const PROFILE = 'PROFILE';
export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';

export const GENERATE_TWILIO_TOKEN = 'GENERATE_TWILIO_TOKEN';
export const GENERATE_TWILIO_TOKEN_REQUEST = 'GENERATE_TWILIO_TOKEN_REQUEST';
export const GENERATE_TWILIO_TOKEN_SUCCESS = 'GENERATE_TWILIO_TOKEN_SUCCESS';
export const GENERATE_TWILIO_TOKEN_FAILURE = 'GENERATE_TWILIO_TOKEN_FAILURE';

export const REGISTER_STEP_ONE = 'REGISTER_STEP_ONE';
export const REGISTER_STEP_ONE_REQUEST = 'REGISTER_STEP_ONE_REQUEST';
export const REGISTER_STEP_ONE_SUCCESS = 'REGISTER_STEP_ONE_SUCCESS';
export const REGISTER_STEP_ONE_FAILURE = 'REGISTER_STEP_ONE_FAILURE';

export const REGISTER_STEP_TWO = 'REGISTER_STEP_TWO';
export const REGISTER_STEP_TWO_REQUEST = 'REGISTER_STEP_TWO_REQUEST';
export const REGISTER_STEP_TWO_SUCCESS = 'REGISTER_STEP_TWO_SUCCESS';
export const REGISTER_STEP_TWO_FAILURE = 'REGISTER_STEP_TWO_FAILURE';

export const REGISTER_STEP_THREE = 'REGISTER_STEP_THREE';
export const REGISTER_STEP_THREE_REQUEST = 'REGISTER_STEP_THREE_REQUEST';
export const REGISTER_STEP_THREE_SUCCESS = 'REGISTER_STEP_THREE_SUCCESS';
export const REGISTER_STEP_THREE_FAILURE = 'REGISTER_STEP_THREE_FAILURE';

export const REGISTER_STEP_FOUR = 'REGISTER_STEP_FOUR';
export const REGISTER_STEP_FOUR_REQUEST = 'REGISTER_STEP_FOUR_REQUEST';
export const REGISTER_STEP_FOUR_SUCCESS = 'REGISTER_STEP_FOUR_SUCCESS';
export const REGISTER_STEP_FOUR_FAILURE = 'REGISTER_STEP_FOUR_FAILURE';

export const REGISTER_DATA = 'REGISTER_DATA';
export const REGISTER_DATA_REQUEST = 'REGISTER_DATA_REQUEST';
export const REGISTER_DATA_SUCCESS = 'REGISTER_DATA_SUCCESS';
export const REGISTER_DATA_FAILURE = 'REGISTER_DATA_FAILURE';

export const GET_REGIONS = 'GET_REGIONS';
export const GET_REGIONS_REQUEST = 'GET_REGIONS_REQUEST';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAILURE = 'GET_REGIONS_FAILURE';

export const GET_DISTICK = 'GET_DISTICK';
export const GET_DISTICK_REQUEST = 'GET_DISTICK_REQUEST';
export const GET_DISTICK_SUCCESS = 'GET_DISTICK_SUCCESS';
export const GET_DISTICK_FAILURE = 'GET_DISTICK_FAILURE';

export const GET_TOWN = 'GET_TOWN';
export const GET_TOWN_REQUEST = 'GET_TOWN_REQUEST';
export const GET_TOWN_SUCCESS = 'GET_TOWN_SUCCESS';
export const GET_TOWN_FAILURE = 'GET_TOWN_FAILURE';

export const VERIFY_CODE = 'VERIFY_CODE';
export const VERIFY_CODE_REQUEST = 'VERIFY_CODE_REQUEST';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAILURE = 'VERIFY_CODE_FAILURE';

export const DELETE_FILE = 'DELETE_FILE';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const SOCIAL_LOGIN_REQUEST = 'SOCIAL_LOGIN_REQUEST';
export const SOCIAL_LOGIN_SUCCESS = 'SOCIAL_LOGIN_SUCCESS';
export const SOCIAL_LOGIN_FAILURE = 'SOCIAL_LOGIN_FAILURE';

export const GET_USER_CERTIFICATE = 'GET_USER_CERTIFICATE';
export const GET_USER_CERTIFICATE_REQUEST = 'GET_USER_CERTIFICATE_REQUEST';
export const GET_USER_CERTIFICATE_SUCCESS = 'GET_USER_CERTIFICATE_SUCCESS';
export const GET_USER_CERTIFICATE_FAILURE = 'GET_USER_CERTIFICATE_FAILURE';

export const GET_USER_COMMENT = 'GET_USER_COMMENT';
export const GET_USER_COMMENT_REQUEST = 'GET_USER_COMMENT_REQUEST';
export const GET_USER_COMMENT_SUCCESS = 'GET_USER_COMMENT_SUCCESS';
export const GET_USER_COMMENT_FAILURE = 'GET_USER_COMMENT_FAILURE';

export const GET_SESSION_COMMENT = 'GET_SESSION_COMMENT';
export const GET_SESSION_COMMENT_REQUEST = 'GET_SESSION_COMMENT_REQUEST';
export const GET_SESSION_COMMENT_SUCCESS = 'GET_SESSION_COMMENT_SUCCESS';
export const GET_SESSION_COMMENT_FAILURE = 'GET_SESSION_COMMENT_FAILURE';

export const GET_ADRESS_IDS = 'GET_ADRESS_IDS';
export const GET_ADRESS_IDS_REQUEST = 'GET_ADRESS_IDS_REQUEST';
export const GET_ADRESS_IDS_SUCCESS = 'GET_ADRESS_IDS_SUCCESS';
export const GET_ADRESS_IDS_FAILURE = 'GET_ADRESS_IDS_FAILURE';

export const OFF_NEW_BRANCH = 'OFF_NEW_BRANCH';

export const SUBMIT_BRANCH = 'SUBMIT_BRANCH';

export const SUBMIT_BENEFIT = 'SUBMIT_BENEFIT';

export const QUIZ_GET = 'QUIZ_GET';
export const QUIZ_GET_REQUEST = 'QUIZ_GET_REQUEST';
export const QUIZ_GET_SUCCESS = 'QUIZ_GET_SUCCESS';
export const QUIZ_GET_FAILURE = 'QUIZ_GET_FAILURE';

export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const GET_PROFILE_INFO_REQUEST = 'GET_PROFILE_INFO_REQUEST';
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS';
export const GET_PROFILE_INFO_FAILURE = 'GET_PROFILE_INFO_FAILURE';

export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';
export const UPDATE_USER_ADDRESS_REQUEST = 'UPDATE_USER_ADDRESS_REQUEST';
export const UPDATE_USER_ADDRESS_SUCCESS = 'UPDATE_USER_ADDRESS_SUCCESS';
export const UPDATE_USER_ADDRESS_FAILURE = 'UPDATE_USER_ADDRESS_FAILURE';

export const UPDATE_TEMPLATE_DEFAULT = 'UPDATE_TEMPLATE_DEFAULT';

export const GET_USER_KEYS = 'GET_USER_KEYS';
export const GET_USER_KEYS_REQUEST = 'GET_USER_KEYS_REQUEST';
export const GET_USER_KEYS_SUCCESS = 'GET_USER_KEYS_SUCCESS';
export const GET_USER_KEYS_FAILURE = 'GET_USER_KEYS_FAILURE';

export const GET_MY_PROFILE_FILES = 'GET_MY_PROFILE_FILES';
export const GET_MY_PROFILE_FILES_REQUEST = 'GET_MY_PROFILE_FILES_REQUEST';
export const GET_MY_PROFILE_FILES_SUCCESS = 'GET_MY_PROFILE_FILES_SUCCESS';
export const GET_MY_PROFILE_FILES_FAILURE = 'GET_MY_PROFILE_FILES_FAILURE';

export const SET_PROFILE_UPDATE = 'SET_PROFILE_UPDATE';
export const SET_PROFILE_UPDATE_REQUEST = 'SET_PROFILE_UPDATE_REQUEST';
export const SET_PROFILE_UPDATE_SUCCESS = 'SET_PROFILE_UPDATE_SUCCESS';
export const SET_PROFILE_UPDATE_FAILURE = 'SET_PROFILE_UPDATE_FAILURE';

export const GET_PROFILE_UPDATE = 'GET_PROFILE_UPDATE';
export const GET_PROFILE_UPDATE_REQUEST = 'GET_PROFILE_UPDATE_REQUEST';
export const GET_PROFILE_UPDATE_SUCCESS = 'GET_PROFILE_UPDATE_SUCCESS';
export const GET_PROFILE_UPDATE_FAILURE = 'GET_PROFILE_UPDATE_FAILURE';

export const UPDATE_FILE = 'UPDATE_FILE';
export const UPDATE_FILE_REQUEST = 'UPDATE_FILE_REQUEST';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAILURE = 'UPDATE_FILE_FAILURE';

export const SET_PASSWORD_UPDATE = 'SET_PASSWORD_UPDATE';
export const SET_PASSWORD_UPDATE_REQUEST = 'SET_PASSWORD_UPDATE_REQUEST';
export const SET_PASSWORD_UPDATE_SUCCESS = 'SET_PASSWORD_UPDATE_SUCCESS';
export const SET_PASSWORD_UPDATE_FAILURE = 'SET_PASSWORD_UPDATE_FAILURE';

export const GET_ACTIVITY_LIST = 'GET_ACTIVITY_LIST';
export const GET_ACTIVITY_LIST_REQUEST = 'GET_ACTIVITY_LIST_REQUEST';
export const GET_ACTIVITY_LIST_SUCCESS = 'GET_ACTIVITY_LIST_SUCCESS';
export const GET_ACTIVITY_LIST_FAILURE = 'GET_ACTIVITY_LIST_FAILURE';

export const GET_ALL_ACTIVITY_LIST = 'GET_ALL_ACTIVITY_LIST';
export const GET_ALL_ACTIVITY_LIST_REQUEST = 'GET_ALL_ACTIVITY_LIST_REQUEST';
export const GET_ALL_ACTIVITY_LIST_SUCCESS = 'GET_ALL_ACTIVITY_LIST_SUCCESS';
export const GET_ALL_ACTIVITY_LIST_FAILURE = 'GET_ALL_ACTIVITY_LIST_FAILURE';

export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_REQUEST = 'UPDATE_ACTIVITY_REQUEST';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'UPDATE_ACTIVITY_FAILURE';

export const ADD_NEW_ACTIVITY = 'ADD_NEW_ACTIVITY';
export const ADD_NEW_ACTIVITY_REQUEST = 'ADD_NEW_ACTIVITY_REQUEST';
export const ADD_NEW_ACTIVITY_SUCCESS = 'ADD_NEW_ACTIVITY_SUCCESS';
export const ADD_NEW_ACTIVITY_FAILURE = 'ADD_NEW_ACTIVITY_FAILURE';

export const GET_PT_BRANCH = 'GET_PT_BRANCH';
export const GET_PT_BRANCH_REQUEST = 'GET_PT_BRANCH_REQUEST';
export const GET_PT_BRANCH_SUCCESS = 'GET_PT_BRANCH_SUCCESS';
export const GET_PT_BRANCH_FAILURE = 'GET_PT_BRANCH_FAILURE';

export const GET_PT_ALL_BRANCH = 'GET_PT_ALL_BRANCH';
export const GET_PT_ALL_BRANCH_REQUEST = 'GET_PT_ALL_BRANCH_REQUEST';
export const GET_PT_ALL_BRANCH_SUCCESS = 'GET_PT_ALL_BRANCH_SUCCESS';
export const GET_PT_ALL_BRANCH_FAILURE = 'GET_PT_ALL_BRANCH_FAILURE';

export const UPDATE_PT_BRANCH = 'UPDATE_PT_BRANCH';
export const UPDATE_PT_BRANCH_REQUEST = 'UPDATE_PT_BRANCH_REQUEST';
export const UPDATE_PT_BRANCH_SUCCESS = 'UPDATE_PT_BRANCH_SUCCESS';
export const UPDATE_PT_BRANCH_FAILURE = 'UPDATE_PT_BRANCH_FAILURE';

export const ADD_NEW_PT_BRANCH = 'ADD_NEW_PT_BRANCH';
export const ADD_NEW_PT_BRANCH_REQUEST = 'ADD_NEW_PT_BRANCH_REQUEST';
export const ADD_NEW_PT_BRANCH_SUCCESS = 'ADD_NEW_PT_BRANCH_SUCCESS';
export const ADD_NEW_PT_BRANCH_FAILURE = 'ADD_NEW_PT_BRANCH_FAILURE';

export const GET_FAVORITE_USERS = 'GET_FAVORITE_USERS';
export const GET_FAVORITE_USERS_REQUEST = 'GET_FAVORITE_USERS_REQUEST';
export const GET_FAVORITE_USERS_SUCCESS = 'GET_FAVORITE_USERS_SUCCESS';
export const GET_FAVORITE_USERS_FAILURE = 'GET_FAVORITE_USERS_FAILURE';

export const GET_PROFICIENCY = 'GET_PROFICIENCY';
export const GET_PROFICIENCY_REQUEST = 'GET_PROFICIENCY_REQUEST';
export const GET_PROFICIENCY_SUCCESS = 'GET_PROFICIENCY_SUCCESS';
export const GET_PROFICIENCY_FAILURE = 'GET_PROFICIENCY_FAILURE';

export const ADD_PROFICIENCY = 'ADD_PROFICIENCY';
export const ADD_PROFICIENCY_REQUEST = 'ADD_PROFICIENCY_REQUEST';
export const ADD_PROFICIENCY_SUCCESS = 'ADD_PROFICIENCY_SUCCESS';
export const ADD_PROFICIENCY_FAILURE = 'ADD_PROFICIENCY_FAILURE';

export const ADD_FAVORITE_USER = 'ADD_FAVORITE_USER';
export const REMOVE_FAVORITE_USER = 'REMOVE_FAVORITE_USER';

export const GET_DIETITIAN_PRICE = 'GET_DIETITIAN_PRICE';
export const GET_DIETITIAN_PRICE_REQUEST = 'GET_DIETITIAN_PRICE_REQUEST';
export const GET_DIETITIAN_PRICE_SUCCESS = 'GET_DIETITIAN_PRICE_SUCCESS';
export const GET_DIETITIAN_PRICE_FAILURE = 'GET_DIETITIAN_PRICE_FAILURE';

export const UPDATE_DIETITIAN_PRICE = 'UPDATE_DIETITIAN_PRICE';
export const UPDATE_DIETITIAN_PRICE_REQUEST = 'UPDATE_DIETITIAN_PRICE_REQUEST';
export const UPDATE_DIETITIAN_PRICE_SUCCESS = 'UPDATE_DIETITIAN_PRICE_SUCCESS';
export const UPDATE_DIETITIAN_PRICE_FAILURE = 'UPDATE_DIETITIAN_PRICE_FAILURE';

export const GET_ALL_DIETITIAN_PROFICIENCY = 'GET_ALL_DIETITIAN_PROFICIENCY';
export const GET_ALL_DIETITIAN_PROFICIENCY_REQUEST =
  'GET_ALL_DIETITIAN_PROFICIENCY_REQUEST';
export const GET_ALL_DIETITIAN_PROFICIENCY_SUCCESS =
  'GET_ALL_DIETITIAN_PROFICIENCY_SUCCESS';
export const GET_ALL_DIETITIAN_PROFICIENCY_FAILURE =
  'GET_ALL_DIETITIAN_PROFICIENCY_FAILURE';

export const GET_DIETITIAN_PROFICIENCY = 'GET_DIETITIAN_PROFICIENCY';
export const GET_DIETITIAN_PROFICIENCY_REQUEST =
  'GET_DIETITIAN_PROFICIENCY_REQUEST';
export const GET_DIETITIAN_PROFICIENCY_SUCCESS =
  'GET_DIETITIAN_PROFICIENCY_SUCCESS';
export const GET_DIETITIAN_PROFICIENCY_FAILURE =
  'GET_DIETITIAN_PROFICIENCY_FAILURE';

export const ADD_DIETITIAN_PROFICIENCY = 'ADD_DIETITIAN_PROFICIENCY';
export const ADD_DIETITIAN_PROFICIENCY_REQUEST =
  'ADD_DIETITIAN_PROFICIENCY_REQUEST';
export const ADD_DIETITIAN_PROFICIENCY_SUCCESS =
  'ADD_DIETITIAN_PROFICIENCY_SUCCESS';
export const ADD_DIETITIAN_PROFICIENCY_FAILURE =
  'ADD_DIETITIAN_PROFICIENCY_FAILURE';

export const GET_SUB_DIETITIAN_PROFICIENCY = 'GET_SUB_DIETITIAN_PROFICIENCY';
export const GET_SUB_DIETITIAN_PROFICIENCY_REQUEST =
  'GET_SUB_DIETITIAN_PROFICIENCY_REQUEST';
export const GET_SUB_DIETITIAN_PROFICIENCY_SUCCESS =
  'GET_SUB_DIETITIAN_PROFICIENCY_SUCCESS';
export const GET_SUB_DIETITIAN_PROFICIENCY_FAILURE =
  'GET_SUB_DIETITIAN_PROFICIENCY_FAILURE';

export const GET_MY_GALLERIES = 'GET_MY_GALLERIES';
export const GET_MY_GALLERIES_REQUEST = 'GET_MY_GALLERIES_REQUEST';
export const GET_MY_GALLERIES_SUCCESS = 'GET_MY_GALLERIES_SUCCESS';
export const GET_MY_GALLERIES_FAILURE = 'GET_MY_GALLERIES_FAILURE';

export const GET_ROOMS = 'GET_ROOMS';
export const GET_ROOMS_REQUEST = 'GET_ROOMS_REQUEST';
export const GET_ROOMS_SUCCESS = 'GET_ROOMS_SUCCESS';
export const GET_ROOMS_FAILURE = 'GET_ROOMS_FAILURE';

export const ADD_TYPE_CREATE = 'ADD_TYPE_CREATE';
export const ADD_TYPE_CREATE_REQUEST = 'ADD_TYPE_CREATE_REQUEST';
export const ADD_TYPE_CREATE_SUCCESS = 'ADD_TYPE_CREATE_SUCCESS';
export const ADD_TYPE_CREATE_FAILURE = 'ADD_TYPE_CREATE_FAILURE';

export const ADD_TYPE_ADDRESS_DELETE = 'ADD_TYPE_ADDRESS_DELETE';
export const ADD_TYPE_ADDRESS_DELETE_REQUEST =
  'ADD_TYPE_ADDRESS_DELETE_REQUEST';
export const ADD_TYPE_ADDRESS_DELETE_SUCCESS =
  'ADD_TYPE_ADDRESS_DELETE_SUCCESS';
export const ADD_TYPE_ADDRESS_DELETE_FAILURE =
  'ADD_TYPE_ADDRESS_DELETE_FAILURE';

export const ADD_TYPE_ADDRESS_UPDATE = 'ADD_TYPE_ADDRESS_UPDATE';
export const ADD_TYPE_ADDRESS_UPDATE_REQUEST =
  'ADD_TYPE_ADDRESS_UPDATE_REQUEST';
export const ADD_TYPE_ADDRESS_UPDATE_SUCCESS =
  'ADD_TYPE_ADDRESS_UPDATE_SUCCESS';
export const ADD_TYPE_ADDRESS_UPDATE_FAILURE =
  'ADD_TYPE_ADDRESS_UPDATE_FAILURE';

export const GET_TYPES = 'GET_TYPES';
export const GET_TYPES_REQUEST = 'GET_TYPES_REQUEST';
export const GET_TYPES_SUCCESS = 'GET_TYPES_SUCCESS';
export const GET_TYPES_FAILURE = 'GET_TYPES_FAILURE';

export const GET_VKI = 'GET_VKI';
export const GET_VKI_REQUEST = 'GET_VKI_REQUEST';
export const GET_VKI_SUCCESS = 'GET_VKI_SUCCESS';
export const GET_VKI_FAILURE = 'GET_VKI_FAILURE';

export const SET_VKI = 'SET_VKI';
export const SET_VKI_REQUEST = 'SET_VKI_REQUEST';
export const SET_VKI_SUCCESS = 'SET_VKI_SUCCESS';
export const SET_VKI_FAILURE = 'SET_VKI_FAILURE';

export const CANCEL_PROFILE = 'CANCEL_PROFILE';
export const CANCEL_PROFILE_REQUEST = 'CANCEL_PROFILE_REQUEST';
export const CANCEL_PROFILE_SUCCESS = 'CANCEL_PROFILE_SUCCESS';
export const CANCEL_PROFILE_FAILURE = 'CANCEL_PROFILE_FAILURE';

export const GET_CANCELLATION_REASONS = 'GET_CANCELLATION_REASONS';
export const GET_CANCELLATION_REASONS_REQUEST =
  'GET_CANCELLATION_REASONS_REQUEST';
export const GET_CANCELLATION_REASONS_SUCCESS =
  'GET_CANCELLATION_REASONS_SUCCESS';
export const GET_CANCELLATION_REASONS_FAILURE =
  'GET_CANCELLATION_REASONS_FAILURE';

export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE';

export const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS';

export const MESSAGE_SEARCH = 'MESSAGE_SEARCH';
export const RESET_MESSAGE_SEARCH = 'RESET_MESSAGE_SEARCH';
export const MESSAGE_SIDEBAR_OPEN = 'MESSAGE_SIDEBAR_OPEN';
export const SEND_NEW_MESSAGE = 'SEND_NEW_MESSAGE';

export const GET_ROOM_MESSAGES = 'GET_ROOM_MESSAGES';
export const GET_ROOM_MESSAGES_REQUEST = 'GET_ROOM_MESSAGES_REQUEST';
export const GET_ROOM_MESSAGES_SUCCESS = 'GET_ROOM_MESSAGES_SUCCESS';
export const GET_ROOM_MESSAGES_FAILURE = 'GET_ROOM_MESSAGES_FAILURE';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

export const SET_ROOM_NAME = 'SET_ROOM_NAME';

export const SESSIONTYPE_ADD_ADDRESS = 'SESSIONTYPE_ADD_ADDRESS';
export const SESSIONTYPE_ADD_ADDRESS_REQUEST =
  'SESSIONTYPE_ADD_ADDRESS_REQUEST';
export const SESSIONTYPE_ADD_ADDRESS_SUCCESS =
  'SESSIONTYPE_ADD_ADDRESS_SUCCESS';
export const SESSIONTYPE_ADD_ADDRESS_FAILURE =
  'SESSIONTYPE_ADD_ADDRESS_FAILURE';

export const SESSIONTYPE_GET_ADDRESS = 'SESSIONTYPE_GET_ADDRESS';
export const SESSIONTYPE_GET_ADDRESS_REQUEST =
  'SESSIONTYPE_GET_ADDRESS_REQUEST';
export const SESSIONTYPE_GET_ADDRESS_SUCCESS =
  'SESSIONTYPE_GET_ADDRESS_SUCCESS';
export const SESSIONTYPE_GET_ADDRESS_FAILURE =
  'SESSIONTYPE_GET_ADDRESS_FAILURE';

export const SESSIONTYPE_GET_GYM_LIST = 'SESSIONTYPE_GET_GYM_LIST';
export const SESSIONTYPE_GET_GYM_LIST_REQUEST =
  'SESSIONTYPE_GET_GYM_LIST_REQUEST';
export const SESSIONTYPE_GET_GYM_LIST_SUCCESS =
  'SESSIONTYPE_GET_GYM_LIST_SUCCESS';
export const SESSIONTYPE_GET_GYM_LIST_FAILURE =
  'SESSIONTYPE_GET_GYM_LIST_FAILURE';

export const SESSIONTYPE_ADD_GYM = 'SESSIONTYPE_ADD_GYM';
export const SESSIONTYPE_ADD_GYM_REQUEST = 'SESSIONTYPE_ADD_GYM_REQUEST';
export const SESSIONTYPE_ADD_GYM_SUCCESS = 'SESSIONTYPE_ADD_GYM_SUCCESS';
export const SESSIONTYPE_ADD_GYM_FAILURE = 'SESSIONTYPE_ADD_GYM_FAILURE';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const GET_NOTIFICATIONS_COUNT = 'GET_NOTIFICATIONS_COUNT';
export const GET_NOTIFICATIONS_COUNT_REQUEST = 'GET_NOTIFICATIONS_COUNT_REQUEST';
export const GET_NOTIFICATIONS_COUNT_SUCCESS = 'GET_NOTIFICATIONS_COUNT_SUCCESS';
export const GET_NOTIFICATIONS_COUNT_FAILURE = 'GET_NOTIFICATIONS_COUNT_FAILURE';

export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';
export const READ_NOTIFICATIONS_REQUEST = 'READ_NOTIFICATIONS_REQUEST';
export const READ_NOTIFICATIONS_SUCCESS = 'READ_NOTIFICATIONS_SUCCESS';
export const READ_NOTIFICATIONS_FAILURE = 'READ_NOTIFICATIONS_FAILURE';

export const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';
export const UPDATE_NOTIFICATION_SETTINGS_REQUEST =
  'UPDATE_NOTIFICATION_SETTINGS_REQUEST';
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS =
  'UPDATE_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_NOTIFICATION_SETTINGS_FAILURE =
  'UPDATE_NOTIFICATION_SETTINGS_FAILURE';

export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS';
export const GET_NOTIFICATION_SETTINGS_REQUEST =
  'GET_NOTIFICATION_SETTINGS_REQUEST';
export const GET_NOTIFICATION_SETTINGS_SUCCESS =
  'GET_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_NOTIFICATION_SETTINGS_FAILURE =
  'GET_NOTIFICATION_SETTINGS_FAILURE';

export const GET_TEST = 'GET_TEST';
export const GET_TEST_REQUEST = 'GET_TEST_REQUEST';
export const GET_TEST_SUCCESS = 'GET_TEST_SUCCESS';
export const GET_TEST_FAILURE = 'GET_TEST_FAILURE';

export const GET_TEST_DETAIL = 'GET_TEST_DETAIL';
export const GET_TEST_DETAIL_REQUEST = 'GET_TEST_DETAIL_REQUEST';
export const GET_TEST_DETAIL_SUCCESS = 'GET_TEST_DETAIL_SUCCESS';
export const GET_TEST_DETAIL_FAILURE = 'GET_TEST_DETAIL_FAILURE';

export const GET_ALL_FACILITY = 'GET_ALL_FACILITY';
export const GET_ALL_FACILITY_REQUEST = 'GET_ALL_FACILITY_REQUEST';
export const GET_ALL_FACILITY_SUCCESS = 'GET_ALL_FACILITY_SUCCESS';
export const GET_ALL_FACILITY_FAILURE = 'GET_ALL_FACILITY_FAILURE';

export const GET_USET_FACILITY = 'GET_USET_FACILITY';
export const GET_USET_FACILITY_REQUEST = 'GET_USET_FACILITY_REQUEST';
export const GET_USET_FACILITY_SUCCESS = 'GET_USET_FACILITY_SUCCESS';
export const GET_USET_FACILITY_FAILURE = 'GET_USET_FACILITY_FAILURE';

export const ADD_FACILITY = 'ADD_FACILITY';

export const GET_MY_BLOGS = 'GET_MY_BLOGS';
export const GET_MY_BLOGS_REQUEST = 'GET_MY_BLOGS_REQUEST';
export const GET_MY_BLOGS_SUCCESS = 'GET_MY_BLOGS_SUCCESS';
export const GET_MY_BLOGS_FAILURE = 'GET_MY_BLOGS_FAILURE';

export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOGS_REQUEST = 'GET_BLOGS_REQUEST';
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS';
export const GET_BLOGS_FAILURE = 'GET_BLOGS_FAILURE';

export const GET_BLOGS_DETAIL = 'GET_BLOGS_DETAIL';
export const GET_BLOGS_DETAIL_REQUEST = 'GET_BLOGS_DETAIL_REQUEST';
export const GET_BLOGS_DETAIL_SUCCESS = 'GET_BLOGS_DETAIL_SUCCESS';
export const GET_BLOGS_DETAIL_FAILURE = 'GET_BLOGS_DETAIL_FAILURE';

export const GET_USER_BLOG_DETAIL = 'GET_USER_BLOG_DETAIL';
export const GET_USER_BLOG_DETAIL_REQUEST = 'GET_USER_BLOG_DETAIL_REQUEST';
export const GET_USER_BLOG_DETAIL_SUCCESS = 'GET_USER_BLOG_DETAIL_SUCCESS';
export const GET_USER_BLOG_DETAIL_FAILURE = 'GET_USER_BLOG_DETAIL_FAILURE';

export const RESET_MESSAGES = 'RESET_MESSAGES';

export const READ_MESSAGE = 'READ_MESSAGE';

export const GET_ALL_ACTIVITY_BRANCH_LIST = 'GET_ALL_ACTIVITY_BRANCH_LIST';
export const GET_ALL_ACTIVITY_BRANCH_LIST_REQUEST =
  'GET_ALL_ACTIVITY_BRANCH_LIST_REQUEST';
export const GET_ALL_ACTIVITY_BRANCH_LIST_SUCCESS =
  'GET_ALL_ACTIVITY_BRANCH_LIST_SUCCESS';
export const GET_ALL_ACTIVITY_BRANCH_LIST_FAILURE =
  'GET_ALL_ACTIVITY_BRANCH_LIST_FAILURE';

export const SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE';

export const GET_FOOTER_INFO = 'GET_FOOTER_INFO';
export const GET_FOOTER_INFO_REQUEST = 'GET_FOOTER_INFO_REQUEST';
export const GET_FOOTER_INFO_SUCCESS = 'GET_FOOTER_INFO_SUCCESS';
export const GET_FOOTER_INFO_FAILURE = 'GET_FOOTER_INFO_FAILURE';

export const AUTH_FILES = 'AUTH_FILES';
export const AUTH_FILES_SUCCESS = 'AUTH_FILES_SUCCESS';

export const REMOVE_GTM_FROM_PT = 'REMOVE_GTM_FROM_PT';

export const ADD_GTM_FROM_PT = 'ADD_GTM_FROM_PT';

export const SEARCH_GYM_FOR_PT = 'SEARCH_GYM_FOR_PT';
export const SEARCH_GYM_FOR_PT_REQUEST = 'SEARCH_GYM_FOR_PT_REQUEST';
export const SEARCH_GYM_FOR_PT_SUCCESS = 'SEARCH_GYM_FOR_PT_SUCCESS';
export const SEARCH_GYM_FOR_PT_FAILURE = 'SEARCH_GYM_FOR_PT_FAILURE';

export const SET_USER_DETAILS = 'SET_USER_DETAILS';

export const GET_PT_USER_WORK_HOME = 'GET_PT_USER_WORK_HOME';
export const GET_PT_USER_WORK_HOME_REQUEST = 'GET_PT_USER_WORK_HOME_REQUEST';
export const GET_PT_USER_WORK_HOME_SUCCESS = 'GET_PT_USER_WORK_HOME_SUCCESS';
export const GET_PT_USER_WORK_HOME_FAILURE = 'GET_PT_USER_WORK_HOME_FAILURE';

export const GET_USER_BLOG_LIST = 'GET_USER_BLOG_LIST';
export const GET_USER_BLOG_LIST_REQUEST = 'GET_USER_BLOG_LIST_REQUEST';
export const GET_USER_BLOG_LIST_SUCCESS = 'GET_USER_BLOG_LIST_SUCCESS';
export const GET_USER_BLOG_LIST_FAILURE = 'GET_USER_BLOG_LIST_FAILURE';

export const GET_USER_BRANCH = 'GET_USER_BRANCH';
export const GET_USER_BRANCH_REQUEST = 'GET_USER_BRANCH_REQUEST';
export const GET_USER_BRANCH_SUCCESS = 'GET_USER_BRANCH_SUCCESS';
export const GET_USER_BRANCH_FAILURE = 'GET_USER_BRANCH_FAILURE';

export const GET_PROFESSIONAL_CALENDAR = 'GET_PROFESSIONAL_CALENDAR';
export const GET_PROFESSIONAL_CALENDAR_REQUEST =
  'GET_PROFESSIONAL_CALENDAR_REQUEST';
export const GET_PROFESSIONAL_CALENDAR_SUCCESS =
  'GET_PROFESSIONAL_CALENDAR_SUCCESS';
export const GET_PROFESSIONAL_CALENDAR_FAILURE =
  'GET_PROFESSIONAL_CALENDAR_FAILURE';

export const GET_USER_FACILITY_LIST = 'GET_USER_FACILITY_LIST';
export const GET_USER_FACILITY_LIST_REQUEST = 'GET_USER_FACILITY_LIST_REQUEST';
export const GET_USER_FACILITY_LIST_SUCCESS = 'GET_USER_FACILITY_LIST_SUCCESS';
export const GET_USER_FACILITY_LIST_FAILURE = 'GET_USER_FACILITY_LIST_FAILURE';

export const GET_GYM_PT_LIST = 'GET_GYM_PT_LIST';
export const GET_GYM_PT_LIST_REQUEST = 'GET_GYM_PT_LIST_REQUEST';
export const GET_GYM_PT_LIST_SUCCESS = 'GET_GYM_PT_LIST_SUCCESS';
export const GET_GYM_PT_LIST_FAILURE = 'GET_GYM_PT_LIST_FAILURE';

export const ADD_DATE_TO_TEMPLATE = 'ADD_DATE_TO_TEMPLATE';
export const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE';

export const SET_SELECTED_DAY = 'SET_SELECTED_DAY';

export const DELETE_DATE_FROM_TEMPLATE = 'DELETE_DATE_FROM_TEMPLATE';
export const ADD_HOUR_TO_CALENDER = 'ADD_HOUR_TO_CALENDER';

export const GET_MY_BRANCHES = 'GET_MY_BRANCHES';
export const GET_MY_BRANCHES_REQUEST = 'GET_MY_BRANCHES_REQUEST';
export const GET_MY_BRANCHES_SUCCESS = 'GET_MY_BRANCHES_SUCCESS';
export const GET_MY_BRANCHES_FAILURE = 'GET_MY_BRANCHES_FAILURE';

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE';

export const APPLY_TEMPLATE_TO_CALENDAR = 'APPLY_TEMPLATE_TO_CALENDAR';
export const APPLY_TEMPLATE_TO_CALENDAR_REQUEST =
  'APPLY_TEMPLATE_TO_CALENDAR_REQUEST';
export const APPLY_TEMPLATE_TO_CALENDAR_SUCCESS =
  'APPLY_TEMPLATE_TO_CALENDAR_SUCCESS';
export const APPLY_TEMPLATE_TO_CALENDAR_FAILURE =
  'APPLY_TEMPLATE_TO_CALENDAR_FAILURE';

export const GET_TEMPLATE_FROM_CALENDAR = 'GET_TEMPLATE_FROM_CALENDAR';
export const GET_TEMPLATE_FROM_CALENDAR_REQUEST =
  'GET_TEMPLATE_FROM_CALENDAR_REQUEST';
export const GET_TEMPLATE_FROM_CALENDAR_SUCCESS =
  'GET_TEMPLATE_FROM_CALENDAR_SUCCESS';
export const GET_TEMPLATE_FROM_CALENDAR_FAILURE =
  'GET_TEMPLATE_FROM_CALENDAR_FAILURE';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const GET_PT_GYM_LIST = 'GET_PT_GYM_LIST';
export const GET_PT_GYM_LIST_REQUEST = 'GET_PT_GYM_LIST_REQUEST';
export const GET_PT_GYM_LIST_SUCCESS = 'GET_PT_GYM_LIST_SUCCESS';
export const GET_PT_GYM_LIST_FAILURE = 'GET_PT_GYM_LIST_FAILURE';

export const GET_DIETITIAN_CLINIC_LIST = 'GET_DIETITIAN_CLINIC_LIST';
export const GET_DIETITIAN_CLINIC_LIST_REQUEST =
  'GET_DIETITIAN_CLINIC_LIST_REQUEST';
export const GET_DIETITIAN_CLINIC_LIST_SUCCESS =
  'GET_DIETITIAN_CLINIC_LIST_SUCCESS';
export const GET_DIETITIAN_CLINIC_LIST_FAILURE =
  'GET_DIETITIAN_CLINIC_LIST_FAILURE';

export const GET_DIETITIAN_SPECIALITY = 'GET_DIETITIAN_SPECIALITY';
export const GET_DIETITIAN_SPECIALITY_REQUEST =
  'GET_DIETITIAN_SPECIALITY_REQUEST';
export const GET_DIETITIAN_SPECIALITY_SUCCESS =
  'GET_DIETITIAN_SPECIALITY_SUCCESS';
export const GET_DIETITIAN_SPECIALITY_FAILURE =
  'GET_DIETITIAN_SPECIALITY_FAILURE';

export const GET_USER_GALERY_LIST = 'GET_USER_GALERY_LIST';
export const GET_USER_GALERY_LIST_REQUEST = 'GET_USER_GALERY_LIST_REQUEST';
export const GET_USER_GALERY_LIST_SUCCESS = 'GET_USER_GALERY_LIST_SUCCESS';
export const GET_USER_GALERY_LIST_FAILURE = 'GET_USER_GALERY_LIST_FAILURE';

export const GET_GYM_CLASS_LIST = 'GET_GYM_CLASS_LIST';
export const GET_GYM_CLASS_LIST_REQUEST = 'GET_GYM_CLASS_LIST_REQUEST';
export const GET_GYM_CLASS_LIST_SUCCESS = 'GET_GYM_CLASS_LIST_SUCCESS';
export const GET_GYM_CLASS_LIST_FAILURE = 'GET_GYM_CLASS_LIST_FAILURE';

export const UPDATE_GYM_SPECIAL_PRICE = 'UPDATE_GYM_SPECIAL_PRICE';

export const SEARCH_PROFESSIONAL = 'SEARCH_PROFESSIONAL';
export const SEARCH_PROFESSIONAL_REQUEST = 'SEARCH_PROFESSIONAL_REQUEST';
export const SEARCH_PROFESSIONAL_SUCCESS = 'SEARCH_PROFESSIONAL_SUCCESS';
export const SEARCH_PROFESSIONAL_FAILURE = 'SEARCH_PROFESSIONAL_FAILURE';

export const SET_SEARCH_PROFESSIONAL_TYPE = 'SET_SEARCH_PROFESSIONAL_TYPE';

export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SAVE_TEMPLATE_REQUEST = 'SAVE_TEMPLATE_REQUEST';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAILURE = 'SAVE_TEMPLATE_FAILURE';

export const GET_TEMPLATE_DETAILS = 'GET_TEMPLATE_DETAILS';
export const GET_TEMPLATE_DETAILS_REQUEST = 'GET_TEMPLATE_DETAILS_REQUEST';
export const GET_TEMPLATE_DETAILS_SUCCESS = 'GET_TEMPLATE_DETAILS_SUCCESS';
export const GET_TEMPLATE_DETAILS_FAILURE = 'GET_TEMPLATE_DETAILS_FAILURE';

export const GET_DAY_OF_CALENDER = 'GET_DAY_OF_CALENDER';
export const GET_DAY_OF_CALENDER_REQUEST = 'GET_DAY_OF_CALENDER_REQUEST';
export const GET_DAY_OF_CALENDER_SUCCESS = 'GET_DAY_OF_CALENDER_SUCCESS';
export const GET_DAY_OF_CALENDER_FAILURE = 'GET_DAY_OF_CALENDER_FAILURE';

export const GET_DAY_DETAIL_OF_CALENDER = 'GET_DAY_DETAIL_OF_CALENDER';
export const GET_DAY_DETAIL_OF_CALENDER_REQUEST =
  'GET_DAY_DETAIL_OF_CALENDER_REQUEST';
export const GET_DAY_DETAIL_OF_CALENDER_SUCCESS =
  'GET_DAY_DETAIL_OF_CALENDER_SUCCESS';
export const GET_DAY_DETAIL_OF_CALENDER_FAILURE =
  'GET_DAY_DETAIL_OF_CALENDER_FAILURE';

export const DELETE_HOUR_OF_CALENDER = 'DELETE_HOUR_OF_CALENDER';
export const DELETE_HOUR_OF_CALENDER_REQUEST =
  'DELETE_HOUR_OF_CALENDER_REQUEST';
export const DELETE_HOUR_OF_CALENDER_SUCCESS =
  'DELETE_HOUR_OF_CALENDER_SUCCESS';
export const DELETE_HOUR_OF_CALENDER_FAILURE =
  'DELETE_HOUR_OF_CALENDER_FAILURE';

export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

export const GET_CLASSIFICATIONS = 'GET_CLASSIFICATIONS';
export const GET_CLASSIFICATIONS_REQUEST = 'GET_CLASSIFICATIONS_REQUEST';
export const GET_CLASSIFICATIONS_SUCCESS = 'GET_CLASSIFICATIONS_SUCCESS';
export const GET_CLASSIFICATIONS_FAILURE = 'GET_CLASSIFICATIONS_FAILURE';

export const GET_WORK_PLACE_CAPACITY = 'GET_WORK_PLACE_CAPACITY';
export const GET_WORK_PLACE_CAPACITY_REQUEST =
  'GET_WORK_PLACE_CAPACITY_REQUEST';
export const GET_WORK_PLACE_CAPACITY_SUCCESS =
  'GET_WORK_PLACE_CAPACITY_SUCCESS';
export const GET_WORK_PLACE_CAPACITY_FAILURE =
  'GET_WORK_PLACE_CAPACITY_FAILURE';

export const GET_HOME_CONTENT = 'GET_HOME_CONTENT';
export const GET_HOME_CONTENT_REQUEST = 'GET_HOME_CONTENT_REQUEST';
export const GET_HOME_CONTENT_SUCCESS = 'GET_HOME_CONTENT_SUCCESS';
export const GET_HOME_CONTENT_FAILURE = 'GET_HOME_CONTENT_FAILURE';

export const REFRESH_LOGIN = 'REFRESH_LOGIN';

export const SET_SELECTION_DATA = 'SET_SELECTION_DATA';

export const CREATE_GROUP_SLOT = 'CREATE_GROUP_SLOT';

export const GET_GROUP_IMAGES = 'GET_GROUP_IMAGES';
export const GET_GROUP_IMAGES_REQUEST = 'GET_GROUP_IMAGES_REQUEST';
export const GET_GROUP_IMAGES_SUCCESS = 'GET_GROUP_IMAGES_SUCCESS';
export const GET_GROUP_IMAGES_FAILURE = 'GET_GROUP_IMAGES_FAILURE';

export const RESET_FORGOT_PASSWORD_STORE = 'RESET_FORGOT_PASSWORD_STORE';

export const SET_RESERVATION = 'SET_RESERVATION';
export const CLEAR_RESERVATION = 'CLEAR_RESERVATION';
export const SET_RESERVATION_DETAIL = 'SET_RESERVATION_DETAIL';

export const DELETE_SLOT = 'DELETE_SLOT';
export const DELETE_ALL_SLOT = 'DELETE_ALL_SLOT';

export const ADD_SLOT = 'ADD_SLOT';

export const GET_WALLET = 'GET_WALLET';

export const GET_WALLET_REQUEST = 'GET_WALLET_REQUEST';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS';
export const GET_WALLET_FAILURE = 'GET_WALLET_FAILURE';

export const GET_BANK_ACCOUNT = 'GET_BANK_ACCOUNT';
export const GET_BANK_ACCOUNT_REQUEST = 'GET_BANK_ACCOUNT_REQUEST';
export const GET_BANK_ACCOUNT_SUCCESS = 'GET_BANK_ACCOUNT_SUCCESS';
export const GET_BANK_ACCOUNT_FAILURE = 'GET_BANK_ACCOUNT_FAILURE';

export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const ADD_BANK_ACCOUNT_REQUEST = 'ADD_BANK_ACCOUNT_REQUEST';
export const ADD_BANK_ACCOUNT_SUCCESS = 'ADD_BANK_ACCOUNT_SUCCESS';
export const ADD_BANK_ACCOUNT_FAILURE = 'ADD_BANK_ACCOUNT_FAILURE';

export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const UPDATE_BANK_ACCOUNT_REQUEST = 'UPDATE_BANK_ACCOUNT_REQUEST';
export const UPDATE_BANK_ACCOUNT_SUCCESS = 'UPDATE_BANK_ACCOUNT_SUCCESS';
export const UPDATE_BANK_ACCOUNT_FAILURE = 'UPDATE_BANK_ACCOUNT_FAILURE';

export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';
export const DELETE_BANK_ACCOUNT_REQUEST = 'DELETE_BANK_ACCOUNT_REQUEST';
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export const DELETE_BANK_ACCOUNT_FAILURE = 'DELETE_BANK_ACCOUNT_FAILURE';

export const GET_WALLET_TRANSACTIONS = 'GET_WALLET_TRANSACTIONS';
export const GET_WALLET_TRANSACTIONS_REQUEST =
  'GET_WALLET_TRANSACTIONS_REQUEST';
export const GET_WALLET_TRANSACTIONS_SUCCESS =
  'GET_WALLET_TRANSACTIONS_SUCCESS';
export const GET_WALLET_TRANSACTIONS_FAILURE =
  'GET_WALLET_TRANSACTIONS_FAILURE';

export const GET_WALLET_TRANSACTIONS_PERPAGE =
  'GET_WALLET_TRANSACTIONS_PERPAGE';
export const GET_WALLET_TRANSACTIONS_PERPAGE_REQUEST =
  'GET_WALLET_TRANSACTIONS_PERPAGE_REQUEST';
export const GET_WALLET_TRANSACTIONS_PERPAGE_SUCCESS =
  'GET_WALLET_TRANSACTIONS_PERPAGE_SUCCESS';
export const GET_WALLET_TRANSACTIONS_PERPAGE_FAILURE =
  'GET_WALLET_TRANSACTIONS_PERPAGE_FAILURE';

export const GET_AREA_FOR_PT = 'GET_AREA_FOR_PT';
export const GET_AREA_FOR_PT_REQUEST = 'GET_AREA_FOR_PT_REQUEST';
export const GET_AREA_FOR_PT_SUCCESS = 'GET_AREA_FOR_PT_SUCCESS ';
export const GET_AREA_FOR_PT_FAILURE = 'GET_AREA_FOR_PT_FAILURE';

export const GET_PT_RESERVATION_CALENDAR = 'GET_PT_RESERVATION_CALENDAR';
export const GET_PT_RESERVATION_CALENDAR_REQUEST =
  'GET_PT_RESERVATION_CALENDAR_REQUEST';
export const GET_PT_RESERVATION_CALENDAR_SUCCESS =
  'GET_PT_RESERVATION_CALENDAR_SUCCESS';
export const GET_PT_RESERVATION_CALENDAR_FAILURE =
  'GET_PT_RESERVATION_CALENDAR_FAILURE';

export const SEND_RESERVATION = 'SEND_RESERVATION';
export const SEND_RESERVATION_REQUEST = 'SEND_RESERVATION_REQUEST';
export const SEND_RESERVATION_SUCCESS = 'SEND_RESERVATION_SUCCESS';
export const SEND_RESERVATION_FAILURE = 'SEND_RESERVATION_FAILURE';

export const SEND_PACKET_RESERVATION = 'SEND_PACKET_RESERVATION';
export const SEND_PACKET_RESERVATION_REQUEST =
  'SEND_PACKET_RESERVATION_REQUEST';
export const SEND_PACKET_RESERVATION_SUCCESS =
  'SEND_PACKET_RESERVATION_SUCCESS';
export const SEND_PACKET_RESERVATION_FAILURE =
  'SEND_PACKET_RESERVATION_FAILURE';
export const SEND_PAYTR = 'SEND_PAYTR';
export const SEND_PAYTR_REQUEST = 'SEND_PAYTR_REQUEST';
export const SEND_PAYTR_SUCCESS = 'SEND_PAYTR_SUCCESS';
export const SEND_PAYTR_FAILURE = 'SEND_PAYTR_FAILURE';

export const PAYTR_RESPONSE = 'PAYTR_RESPONSE';
export const PAYTR_RESPONSE_REQUEST = 'PAYTR_RESPONSE_REQUEST';
export const PAYTR_RESPONSE_SUCCESS = 'PAYTR_RESPONSE_SUCCESS';
export const PAYTR_RESPONSE_FAILURE = 'PAYTR_RESPONSE_FAILURE';

export const GET_PT_FOR_GYM = 'GET_PT_FOR_GYM';
export const GET_PT_FOR_GYM_REQUEST = 'GET_PT_FOR_GYM_REQUEST';
export const GET_PT_FOR_GYM_SUCCESS = 'GET_PT_FOR_GYM_SUCCESS';
export const GET_PT_FOR_GYM_FAILURE = 'GET_PT_FOR_GYM_FAILURE';

export const GET_PT_RESERVATION_STATE_DATA = 'GET_PT_RESERVATION_STATE_DATA';
export const GET_PT_RESERVATION_STATE_DATA_SUCCESS =
  'GET_PT_RESERVATION_STATE_DATA_SUCCESS';
export const GET_PT_RESERVATION_STATE_DATA_REQUEST =
  'GET_PT_RESERVATION_STATE_DATA_REQUEST';
export const GET_PT_RESERVATION_STATE_DATA_FAILURE =
  'GET_PT_RESERVATION_STATE_DATA_FAILURE';

export const GET_PT_RESERVATION_PACKAGE = 'GET_PT_RESERVATION_PACKAGE';
export const GET_PT_RESERVATION_PACKAGE_SUCCESS =
  'GET_PT_RESERVATION_PACKAGE_SUCCESS';
export const GET_PT_RESERVATION_PACKAGE_REQUEST =
  'GET_PT_RESERVATION_PACKAGE_REQUEST';
export const GET_PT_RESERVATION_PACKAGE_FAILURE =
  'GET_PT_RESERVATION_PACKAGE_FAILURE';

export const GET_DT_RESERVATION_PACKAGE = 'GET_DT_RESERVATION_PACKAGE';
export const GET_DT_RESERVATION_PACKAGE_SUCCESS =
  'GET_DT_RESERVATION_PACKAGE_SUCCESS';
export const GET_DT_RESERVATION_PACKAGE_REQUEST =
  'GET_DT_RESERVATION_PACKAGE_REQUEST';
export const GET_DT_RESERVATION_PACKAGE_FAILURE =
  'GET_DT_RESERVATION_PACKAGE_FAILURE';

export const DELETE_DT_RESERVATION_PACKAGE = 'DELETE_DT_RESERVATION_PACKAGE';

export const GET_PT_RESERVATION_PACKAGE_CLASS =
  'GET_PT_RESERVATION_PACKAGE_CLASS';
export const GET_PT_RESERVATION_PACKAGE_CLASS_SUCCESS =
  'GET_PT_RESERVATION_PACKAGE_CLASS_SUCCESS';
export const GET_PT_RESERVATION_PACKAGE_CLASS_REQUEST =
  'GET_PT_RESERVATION_PACKAGE_CLASS_REQUEST';
export const GET_PT_RESERVATION_PACKAGE_CLASS_FAILURE =
  'GET_PT_RESERVATION_PACKAGE_CLASS_FAILURE';

export const GET_PT_PACKAGE_EXERCISE_LIST = 'GET_PT_PACKAGE_EXERCISE_LIST';
export const GET_PT_PACKAGE_EXERCISE_LIST_SUCCESS =
  'GET_PT_PACKAGE_EXERCISE_LIST_SUCCESS';
export const GET_PT_PACKAGE_EXERCISE_LIST_REQUEST =
  'GET_PT_PACKAGE_EXERCISE_LIST_REQUEST';
export const GET_PT_PACKAGE_EXERCISE_LIST_FAILURE =
  'GET_PT_PACKAGE_EXERCISE_LIST_FAILURE';

export const GET_PT_PACKAGE_DETAIL_CLASS = 'GET_PT_PACKAGE_DETAIL_CLASS';
export const GET_PT_PACKAGE_DETAIL_CLASS_SUCCESS =
  'GET_PT_PACKAGE_DETAIL_CLASS_SUCCESS';
export const GET_PT_PACKAGE_DETAIL_CLASS_REQUEST =
  'GET_PT_PACKAGE_DETAIL_CLASS_REQUEST';
export const GET_PT_PACKAGE_DETAIL_CLASS_FAILURE =
  'GET_PT_PACKAGE_DETAIL_CLASS_FAILURE';

export const GET_PT_PACKAGE_TEST_QUESTION = 'GET_PT_PACKAGE_TEST_QUESTION';
export const GET_PT_PACKAGE_TEST_QUESTION_SUCCESS =
  'GET_PT_PACKAGE_TEST_QUESTION_SUCCESS';
export const GET_PT_PACKAGE_TEST_QUESTION_REQUEST =
  'GET_PT_PACKAGE_TEST_QUESTION_REQUEST';
export const GET_PT_PACKAGE_TEST_QUESTION_FAILURE =
  'GET_PT_PACKAGE_TEST_QUESTION_FAILURE';

export const SET_PT_PACKAGE_TEST_QUESTION = 'SET_PT_PACKAGE_TEST_QUESTION';
export const SET_PT_PACKAGE_TEST_QUESTION_SUCCESS =
  'SET_PT_PACKAGE_TEST_QUESTION_SUCCESS';
export const SET_PT_PACKAGE_TEST_QUESTION_REQUEST =
  'SET_PT_PACKAGE_TEST_QUESTION_REQUEST';
export const SET_PT_PACKAGE_TEST_QUESTION_FAILURE =
  'SET_PT_PACKAGE_TEST_QUESTION_FAILURE';

export const SET_PT_PACKAGE_EXERCISE_LIST = 'SET_PT_PACKAGE_EXERCISE_LIST';
export const DELETE_PT_PACKAGE_EXERCISE_LIST =
  'DELETE_PT_PACKAGE_EXERCISE_LIST';

export const GET_RESERVATION_DETAIL = 'GET_RESERVATION_DETAIL';
export const GET_RESERVATION_DETAIL_SUCCESS = 'GET_RESERVATION_DETAIL_SUCCESS';
export const GET_RESERVATION_DETAIL_REQUEST = 'GET_RESERVATION_DETAIL_REQUEST';
export const GET_RESERVATION_DETAIL_FAILURE = 'GET_RESERVATION_DETAIL_FAILURE';

export const PT_RESERVATION_FUNC = 'PT_RESERVATION_FUNC';
export const PT_RESERVATION_FUNC_REQUEST = 'PT_RESERVATION_FUNC_REQUEST';
export const PT_RESERVATION_FUNC_SUCCESS = 'PT_RESERVATION_FUNC_SUCCESS';
export const PT_RESERVATION_FUNC_FAILURE = 'PT_RESERVATION_FUNC_FAILURE';

//DİETT
export const GET_DT_RESERVATION_STATE_DATA = 'GET_DT_RESERVATION_STATE_DATA';
export const GET_DT_RESERVATION_STATE_DATA_SUCCESS =
  'GET_DT_RESERVATION_STATE_DATA_SUCCESS';
export const GET_DT_RESERVATION_STATE_DATA_REQUEST =
  'GET_DT_RESERVATION_STATE_DATA_REQUEST';
export const GET_DT_RESERVATION_STATE_DATA_FAILURE =
  'GET_DT_RESERVATION_STATE_DATA_FAILURE';
export const DT_RESERVATION_FUNC = 'DT_RESERVATION_FUNC';
export const DT_RESERVATION_FUNC_REQUEST = 'DT_RESERVATION_FUNC_REQUEST';
export const DT_RESERVATION_FUNC_SUCCESS = 'DT_RESERVATION_FUNC_SUCCESS';
export const DT_RESERVATION_FUNC_FAILURE = 'DT_RESERVATION_FUNC_FAILURE';

export const GET_DT_RESERVATION_CALENDAR = 'GET_DT_RESERVATION_CALENDAR';
export const GET_DT_RESERVATION_CALENDAR_REQUEST =
  'GET_DT_RESERVATION_CALENDAR_REQUEST';
export const GET_DT_RESERVATION_CALENDAR_SUCCESS =
  'GET_DT_RESERVATION_CALENDAR_SUCCESS';
export const GET_DT_RESERVATION_CALENDAR_FAILURE =
  'GET_DT_RESERVATION_CALENDAR_FAILURE';

//GYM
export const GET_GYM_RESERVATION_STATE_DATA = 'GET_GYM_RESERVATION_STATE_DATA';
export const GET_GYM_RESERVATION_STATE_DATA_SUCCESS =
  'GET_GYM_RESERVATION_STATE_DATA_SUCCESS';
export const GET_GYM_RESERVATION_STATE_DATA_REQUEST =
  'GET_GYM_RESERVATION_STATE_DATA_REQUEST';
export const GET_GYM_RESERVATION_STATE_DATA_FAILURE =
  'GET_GYM_RESERVATION_STATE_DATA_FAILURE';
export const GYM_RESERVATION_FUNC = 'GYM_RESERVATION_FUNC';
export const GYM_RESERVATION_FUNC_REQUEST = 'GYM_RESERVATION_FUNC_REQUEST';
export const GYM_RESERVATION_FUNC_SUCCESS = 'GYM_RESERVATION_FUNC_SUCCESS';
export const GYM_RESERVATION_FUNC_FAILURE = 'GYM_RESERVATION_FUNC_FAILURE';
export const GET_GYM_RESERVATION_CALENDAR = 'GET_GYM_RESERVATION_CALENDAR';
export const GET_GYM_RESERVATION_CALENDAR_REQUEST =
  'GET_GYM_RESERVATION_CALENDAR_REQUEST';
export const GET_GYM_RESERVATION_CALENDAR_SUCCESS =
  'GET_GYM_RESERVATION_CALENDAR_SUCCESS';
export const GET_GYM_RESERVATION_CALENDAR_FAILURE =
  'GET_GYM_RESERVATION_CALENDAR_FAILURE';
export const GET_GYM_RESERVATION_DATA = 'GET_GYM_RESERVATION_DATA';
export const GET_GYM_RESERVATION_DATA_REQUEST =
  'GET_GYM_RESERVATION_DATA_REQUEST';
export const GET_GYM_RESERVATION_DATA_SUCCESS =
  'GET_GYM_RESERVATION_DATA_SUCCESS';
export const GET_GYM_RESERVATION_DATA_FAILURE =
  'GET_GYM_RESERVATION_DATA_FAILURE';
//USER
export const USER_RESERVATION_FUNC = 'USER_RESERVATION_FUNC';
export const USER_RESERVATION_FUNC_REQUEST = 'USER_RESERVATION_FUNC_REQUEST';
export const USER_RESERVATION_FUNC_SUCCESS = 'USER_RESERVATION_FUNC_SUCCESS';
export const USER_RESERVATION_FUNC_FAILURE = 'USER_RESERVATION_FUNC_FAILURE';

export const GET_USER_RESERVATION_STATE_DATA =
  'GET_USER_RESERVATION_STATE_DATA';
export const GET_USER_RESERVATION_STATE_DATA_SUCCESS =
  'GET_USER_RESERVATION_STATE_DATA_SUCCESS';
export const GET_USER_RESERVATION_STATE_DATA_REQUEST =
  'GET_USER_RESERVATION_STATE_DATA_REQUEST';
export const GET_USER_RESERVATION_STATE_DATA_FAILURE =
  'GET_USER_RESERVATION_STATE_DATA_FAILURE';

export const GET_DT_SERVICES = 'GET_DT_SERVICES';
export const GET_DT_SERVICES_REQUEST = 'GET_DT_SERVICES_REQUEST';
export const GET_DT_SERVICES_SUCCESS = 'GET_DT_SERVICES_SUCCESS';
export const GET_DT_SERVICES_FAILURE = 'GET_DT_SERVICES_FAILURE';

export const CLEAR_RESERVATIONCALENDAR = 'CLEAR_RESERVATIONCALENDAR';

// export const SHOW_ACTIVITY_LIST_MODAL = 'SHOW_ACTIVITY_LIST_MODAL';
// export const HIDE_ACTIVITY_LIST_MODAL = 'HIDE_ACTIVITY_LIST_MODAL';

export const GET_PACKET_DETAIL = 'GET_PACKET_DETAIL';
export const GET_PACKET_DETAIL_SUCCESS = 'GET_PACKET_DETAIL_SUCCESS';
export const GET_PACKET_DETAIL_REQUEST = 'GET_PACKET_DETAIL_REQUEST';
export const GET_PACKET_DETAIL_FAILURE = 'GET_PACKET_DETAIL_FAILURE';
export const SET_PACKET_RESERVATION = 'SET_PACKET_RESERVATION';
export const CLEAR_PACKET_RESERVATION = 'CLEAR_PACKET_RESERVATION';

export const GET_GROUP_LESSON_DETAIL = 'GET_GROUP_LESSON_DETAIL';
export const GET_GROUP_LESSON_DETAIL_SUCCESS = 'GET_GROUP_LESSON_DETAIL_SUCCESS';
export const GET_GROUP_LESSON_DETAIL_REQUEST = 'GET_GROUP_LESSON_DETAIL_REQUEST';
export const GET_GROUP_LESSON_DETAIL_FAILURE = 'GET_GROUP_LESSON_DETAIL_FAILURE';
export const SET_GROUP_LESSON_RESERVATION = 'SET_GROUP_LESSON_RESERVATION';
export const CLEAR_GROUP_LESSON_RESERVATION = 'CLEAR_GROUP_LESSON_RESERVATION';


export const GET_USER_MY_PACKET = 'GET_USER_MY_PACKET';
export const GET_USER_MY_PACKET_REQUEST = 'GET_USER_MY_PACKET_REQUEST';
export const GET_USER_MY_PACKET_SUCCESS = 'GET_USER_MY_PACKET_SUCCESS';
export const GET_USER_MY_PACKET_FAILURE = 'GET_USER_MY_PACKET_FAILURE';

export const GET_USER_MY_PACKET_DETAIL = 'GET_USER_MY_PACKET_DETAIL';
export const GET_USER_MY_PACKET_DETAIL_REQUEST =
  'GET_USER_MY_PACKET_DETAIL_REQUEST';
export const GET_USER_MY_PACKET_DETAIL_SUCCESS =
  'GET_USER_MY_PACKET_DETAIL_SUCCESS';
export const GET_USER_MY_PACKET_DETAIL_FAILURE =
  'GET_USER_MY_PACKET_DETAIL_FAILURE';

export const GET_USER_PACKET_LESSON_DETAIL = 'GET_USER_PACKET_LESSON_DETAIL';
export const GET_USER_PACKET_LESSON_DETAIL_REQUEST =
  'GET_USER_PACKET_LESSON_DETAIL_REQUEST';
export const GET_USER_PACKET_LESSON_DETAIL_SUCCESS =
  'GET_USER_PACKET_LESSON_DETAIL_SUCCESS';
export const GET_USER_PACKET_LESSON_DETAIL_FAILURE =
  'GET_USER_PACKET_LESSON_DETAIL_FAILURE';

export const SET_USER_PACKET_LESSON_COMPLETE =
  'SET_USER_PACKET_LESSON_COMPLETE';
export const SET_USER_PACKET_LESSON_COMPLETE_REQUEST =
  'SET_USER_PACKET_LESSON_COMPLETE_REQUEST';
export const SET_USER_PACKET_LESSON_COMPLETE_SUCCESS =
  'SET_USER_PACKET_LESSON_COMPLETE_SUCCESS';
export const SET_USER_PACKET_LESSON_COMPLETE_FAILURE =
  'SET_USER_PACKET_LESSON_COMPLETE_FAILURE';

export const GET_USER_MY_PACKET_EXERCISE_DETAIL =
  'GET_USER_MY_PACKET_EXERCISE_DETAIL';
export const GET_USER_MY_PACKET_EXERCISE_DETAIL_REQUEST =
  'GET_USER_MY_PACKET_EXERCISE_DETAIL_REQUEST';
export const GET_USER_MY_PACKET_EXERCISE_DETAIL_SUCCESS =
  'GET_USER_MY_PACKET_EXERCISE_DETAIL_SUCCESS';
export const GET_USER_MY_PACKET_EXERCISE_DETAIL_FAILURE =
  'GET_USER_MY_PACKET_EXERCISE_DETAIL_FAILURE';

export const TRANSFER_REFUND = 'TRANSFER_REFUND';
export const TRANSFER_REFUND_REQUEST = 'TRANSFER_REFUND_REQUEST';
export const TRANSFER_REFUND_SUCCESS = 'TRANSFER_REFUND_SUCCESS';
export const TRANSFER_REFUND_FAILURE = 'TRANSFER_REFUND_FAILURE';

export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const SEARCH_RESULTS_REQUEST = 'SEARCH_RESULTS_REQUEST';
export const SEARCH_RESULTS_SUCCESS = 'SEARCH_RESULTS_SUCCESS';
export const SEARCH_RESULTS_FAILURE = 'SEARCH_RESULTS_FAILURE';

export const GET_SYSTEM_COMMENTS = 'GET_SYSTEM_COMMENTS';
export const GET_SYSTEM_COMMENTS_REQUEST = 'GET_SYSTEM_COMMENTS_REQUEST';
export const GET_SYSTEM_COMMENTS_SUCCESS = 'GET_SYSTEM_COMMENTS_SUCCESS';
export const GET_SYSTEM_COMMENTS_FAILURE = 'GET_SYSTEM_COMMENTS_FAILURE';

export const GET_PRIVATE_PACKAGE_DATA = 'GET_PRIVATE_PACKAGE_DATA';
export const GET_PRIVATE_PACKAGE_DATA_REQUEST = 'GET_PRIVATE_PACKAGE_DATA_REQUEST';
export const GET_PRIVATE_PACKAGE_DATA_SUCCESS = 'GET_PRIVATE_PACKAGE_DATA_SUCCESS';
export const GET_PRIVATE_PACKAGE_DATA_FAILURE = 'GET_PRIVATE_PACKAGE_DATA_FAILURE';