import { HTTP_REQUEST, GET_USER_BRANCH } from '../../constants';

export const getUserBranchList = (id,session) => async (dispatch) => {
  let url = `/user/pt-price/${id}`;
  if(session) url+=`?session_type=${session}`

  await dispatch({
    type: HTTP_REQUEST,
    payload: {
      method: 'GET',
      url,
      label: GET_USER_BRANCH,
      transformData: (data) => data.data,
    },
  });
};
