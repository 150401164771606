import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { Title } from 'components';
import Pt from './PT';


import image from '../../assets/session-type.jpg';

const MyPackages = () => {
  const { t } = useTranslation();

  const [bannerActive, setBannerActive] = useState(true);

 



  let icons = [];

  return (
    <div>
      <Container>
        <Row>
          <Col lg="12">
            <Title fontSize="14pt" style={{ padding: 15 }} textAlign="left">
              {t('my packages')}
            </Title>
          </Col>
           
          <Col lg="4" style={{ display: bannerActive ? '' : 'none' }}>
            <ImageBanner src={image} />
          </Col>
          <Col lg={bannerActive ? 8 : 12}>
            <Pt icons={icons} setBannerActive={setBannerActive} />
          </Col>
        
        </Row>
      </Container>
    </div>
  );
};

const ImageBanner = styled.section`
  width: 100%;
  height: 285px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: url('${(props) => props.src}');
  background-size: cover;
  background-repeat: no-repeat;
`;

export default MyPackages;
