import React, { useEffect,useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, Text } from 'components';
import { getWalletTransactionsPerPage } from 'actions/userProfileActions/walletActions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { device } from 'utils';
import axios from 'axios';
import {toast} from 'react-toastify';
import { Material, MultiContract } from 'components';
import {getStaticPage} from 'actions'
const Data = ({ paymentType, range, changed }) => {
  const formRef = useRef(null);
  const { t } = useTranslation();
  const [openContract,setOpenContract] = useState(false)
  const { data, totalPage } = useSelector(
    (state) => state?.userProfile?.wallet?.transactionsPerPage
  );
  const auth = useSelector((state) => state.auth);
  const accessToken = auth?.accessToken;
  const staticPages = useSelector((state) => state.staticPages);
  const [openModal,setOpenModal] = useState(false)

  const [page, setPage] = useState(1);
  const [cardData,setCardData] = useState({})
  const pageChangeHandler = (event, pageNumber) => {
    setPage(pageNumber);
  };

  const dispatch = useDispatch();

  function getPaymentInfoString(subKindTitle, kindTitle, typeTitle) {
    let string = '';
    if (subKindTitle) string += subKindTitle + ' ';
    if (kindTitle) {
      string += kindTitle;
    }
    if (string?.trim() == '') {
      string += typeTitle;
    }
    /*{(item?.elaboration?.sub_kind?.title || '') +
    '' +
    (item?.elaboration?.kind?.title || '') ||
    item?.type?.title}*/
    return string;
  }
  function openPaymentModal(id) {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/user/transactions/pay-penalty`,
      headers: { 
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios({ ...config, data: {transaction_id:id} })
    .then(function (data) {
      setOpenModal(data?.data?.data);

    })
    .catch(function (err) {
      toast.error(err?.message || t('error'), {
        position: 'bottom-right',
        autoClose: 2000,
      });
    });
  }
  useEffect(() => {
    dispatch(getWalletTransactionsPerPage(25, page, paymentType, range));
  }, [page, paymentType, range]);

  useEffect(() => {
    setPage(1);
  }, [changed]);
  useEffect(()=>{
    dispatch(getStaticPage('uye-mesafeli-hizmet-sozlesmesi'));
    dispatch(getStaticPage('uye-on-bilgilendirme-formu'));
  },[])
  return (
    <div>
      <StyledModal show={openModal} onHide={() => {setOpenModal(false)}}>
       <ModalHeader>
       <text style={{fontWeight:500}}>Ödenecek Ceza Tutarı : {openModal?.payment_amount?.toFixed(2)} TL</text>
       <button onClick={()=>{setOpenModal(false)}} style={{background: 'transparent'}}>X</button>
       </ModalHeader>
        <>
          <InfoContainer>
            <DataContainer>
              <Info borderDisable>
                <Text2 style={{ fontWeight: 800 }}>{t('Card Information')}</Text2>
              </Info>

              <Material.TextField
                label={t('name on the card')}
                type="text"
                name="holder_name"
                // defaultValue={defaultCardName}
                onBlur={(e) => {
                  setCardData({...cardData,holder_name:e.target.value});
                }}
              />

              <Material.TextField
                mask="9999 9999 9999 9999"
                label={t('Enter Card Number')}
                type="text"
                name="card_number"
                // defaultValue={defaultCardNo} /*reservation?.data?.card_number*/
                onBlur={(e) => {
                  setCardData({...cardData,card_number:e.target.value});

                }}
              />
              <Info borderDisable>
                <Material.TextField
                  label={t('EXP')}
                  type="text"
                  name="skt"
                  mask="99/99"
                  // defaultValue={
                  //   defaultSKT
                  // } /**    reservation?.data?.expiration_month +
                  //                   '/' +
                  //                 reservation?.data?.expiration_year */
                  onBlur={(e) => {
                    var sktArr = e.target.value.split('/');
                    setCardData({...cardData,expiry_month: sktArr[0],expiry_year: sktArr[1]});

                    /*  dispatch(
                  setReservation({
                    expiration_month: sktArr[0],
                    expiration_year: sktArr[1],
                  })
                );*/
                  }}
                />
                <Material.TextField 
                  mask="999"
                  label="CVV"
                  type="text"
                  name="cvv"
                  // defaultValue={defaultCVV} /**reservation?.data?.cvc */
                  onBlur={(e) => {
                    setCardData({...cardData,cvv:e.target.value});

                  }}
                />
              </Info>
            </DataContainer>
            <div style={{ padding: '10px' }}>
              <text>
                {t(
                  'For your security, this transaction will be carried out with 3D secure'
                )}
              </text>
            </div>
            <div style={{ padding: '10px' }}>
              <Material.CheckBox
                checked={cardData?.is_contracts_accepted}
                onChange={() => {
                  if (cardData?.is_contracts_accepted) {
                    setCardData({...cardData,is_contracts_accepted: false})
                    // dispatch(setReservation({ is_contracts_accepted: false }));
                  } else {
                    setOpenContract(true);
                  }
                }}
                label={
                  <div>
                    <span className="underline-text" onClick={() => {}}>
                      {t(
                        'I have read and approve the Preliminary Information Conditions and the Distance Sales Agreement'
                      )}
                    </span>
                  </div>
                }
              />
            </div>
          </InfoContainer>
          <BigPayButton onClick={()=>{formRef.current.submit()}}>ÖDEME YAP</BigPayButton>
          <form ref={formRef} action="https://www.paytr.com/odeme" method="POST">
        {/* <input type="hidden" name="cc_owner" value="PAYTR TEST" />
        <input type="hidden" name="card_number" value="9792030394440796" />
        <input type="hidden" name="expiry_month" value="12" />
        <input type="hidden" name="expiry_year" value="24" />
        <input type="hidden" name="cvv" value="000" /> */}

        <input type="hidden" name="cc_owner" value={cardData?.holder_name} />
        <input type="hidden" name="card_number" value={cardData?.card_number} />
        <input type="hidden" name="expiry_month" value={cardData?.expiry_month} />
        <input type="hidden" name="expiry_year" value={cardData?.expiry_year} />
        <input type="hidden" name="cvv" value={cardData?.cvv} />

        <input type="hidden" name="card_type" value={openModal?.card_type} />
        <input type="hidden" name="currency" value={openModal?.currency} />
        <input type="hidden" name="debug_on" value={openModal?.debug_on} />
        <input type="hidden" name="email" value={openModal?.email} />
        <input
          type="hidden"
          name="installment_count"
          value={openModal?.installment_count}
        />
        <input type="hidden" name="lang" value={openModal?.lang} />
        <input
          type="hidden"
          name="max_installment"
          value={openModal?.max_installment}
        />
        <input
          type="hidden"
          name="merchant_fail_url"
          value={openModal?.merchant_fail_url}
        />
        <input
          type="hidden"
          name="merchant_id"
          value={openModal?.merchant_id}
        />
        <input
          type="hidden"
          name="merchant_oid"
          value={openModal?.merchant_oid}
        />
        <input
          type="hidden"
          name="merchant_ok_url"
          value={openModal?.merchant_ok_url}
        />
        <input
          type="hidden"
          name="no_installment"
          value={openModal?.no_installment}
        />
        <input
          type="hidden"
          name="non3d_test_failed"
          value={openModal?.non3d_test_failed}
        />
        <input type="hidden" name="non_3d" value={openModal?.non_3d} />
        <input
          type="hidden"
          name="payment_amount"
          value={openModal?.payment_amount}
        />
        <input
          type="hidden"
          name="payment_type"
          value={openModal?.payment_type}
        />
        <input
          type="hidden"
          name="paytr_token"
          value={openModal?.paytr_token}
        />

        <input type="hidden" name="test_mode" value={openModal?.test_mode} />
        <input
          type="hidden"
          name="user_address"
          value={openModal?.user_address}
        />
        <input
          type="hidden"
          name="user_basket"
          value={openModal?.user_basket}
        />
        <input type="hidden" name="user_ip" value={openModal?.user_ip} />
        <input type="hidden" name="user_name" value={openModal?.user_name} />
        <input
          type="hidden"
          name="user_phone"
          value={openModal?.user_phone}
        />
      </form>
          <StyledModalContract show={openContract} onHide={() => setOpenContract(false)}>
            <MultiContract
              acceptKvkk={true} 
              setAccept={() => {
                // dispatch(setReservation({ is_contracts_accepted: true }));
                setCardData({...cardData,is_contracts_accepted: true})

              }}
              setOpenModal={setOpenContract}
              confirmationData={staticPages.data}
              userTypeId={1}
            />
          </StyledModalContract>
        </>
      </StyledModal>
      {data?.length > 0 ? (
        <div>
          <Table>
            <table>
              <tbody>
                <tr>
                  <th> {t('date')}</th>
                  <th>{t('Service provider')}</th>
                  <th>{t('Operation type')}</th>
                  <th>{t('Payment method')}</th>
                  <th>{t('Amount')}</th>
                  <th></th>
                </tr>
                {data &&
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {moment(item.created_at, 'DD.MM.YYYY hh:mm').format(
                          'LLL'
                        )}
                      </td>
                      <td>{item?.service_provider?.name}</td>

                      <td>
                        {getPaymentInfoString(
                          item?.elaboration?.sub_kind?.title,
                          item?.elaboration?.kind?.title,
                          item?.type?.title
                        )}
                      </td>

                      <td>{item.payment_type?.title}</td>
                      <td>
                        {item?.amount_type}
                        {parseFloat(item?.amount).toFixed(2)}₺
                      </td>
                      <td>
                        {item?.type?.key == 'penalty' &&
                          item?.status?.key == 'active' &&
                          item?.payment_type?.key == 'card' && (
                            <PayButton onClick={()=>{openPaymentModal(item?.id)}}>
                              Öde
                            </PayButton>
                          )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            
          </Table>
          <Pagination
            mt="10px"
            page={page}
            onChange={pageChangeHandler}
            count={totalPage}
          />
        </div>
      ) : (
        <Capsule>
          {' '}
          <CapsuleItem>
            <Text color="dark" textAlign="left" fontWeight="500" p="5px">
              {t('No data found')}
            </Text>
          </CapsuleItem>
        </Capsule>
      )}
     
    </div>
  );
};
const StyledModal = styled(Modal)`
  .modal-content {
    width: 900px;
    background-color: var(--white1);
    padding: 15px 30px;
    @media ${device.sm} {
      height: 70vh;
      width: 90vw;
      overflow: scroll;
    }
  }
`;
const StyledModalContract = styled(Modal)`
  .modal-content {
    width: 600px;
    background-color: var(--white1);
    padding: 15px 30px;
    @media ${device.sm} {
      height: 70vh;
      width: 90vw;
      overflow: scroll;
    }
  }
`;
const Table = styled.div`
  width: 100%;
  height: auto;
  margin-top: 15px;

  table {
    tr {
      th {
        background: var(--blue3);
        padding: 7px;
      }
      td {
        padding: 14px 7px;
        font-size: 11pt;
        font-weight: 500;
      }
    }
  }
`;
const Capsule = styled.div`
  width: 75%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-left: 15px;
  margin: 10px 0;

  &:before {
    content: '';
    width: 3px;
    background: #ffc47c;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const CapsuleItem = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 7px;
  border-bottom: 1px solid #ddd;

  tr {
    background: transparent !important;

    td {
      padding: 7px 0;
    }
  }
`;

const PayButton = styled.button`
  background: transparent;
  text-decoration: underline;
`;
const BigPayButton = styled.button`
  background: var(--blue);
  width:200px;
  color:white;
  font-weight:bold;
  text-align:center;
  padding:15px;
  border-radius:20px;
`;
const Info = styled.div`
  display: flex;
  justify-content: space-between;
  border-style: ${(p) => (p.borderDisable ? 'none' : 'solid')};
  border-color: rgba(144, 144, 144, 0.5);
  border-width: 0 0 1px 0;
  padding: 10px 5px;
`;
const InfoContainer = styled.div`
  margin-top: 10px;
  width: 586px;
  background: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  @media ${device.sm} {
    width: 100%;
  }
`;
const DataContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c6c6c6;
  padding: 5px 20px;
`;
const Text2 = styled.text`
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  color: ${(props) => props.color || 'black'};
  @media ${device.sm} {
    font-size: 0.7rem;
  }
`;
const ModalHeader = styled.div`
  display:flex;
  width:100%;
  justify-content:space-between;
  padding:0 20px;
`
export default Data;
