
export const GYM = 'gym';
export const ONLINE = 'online';
export const HOME_PARK = 'home_park';
export const CLINIC = 'clinic';


export const SESSION_KEYS = {
  [GYM]: 'Sports Field',
  [ONLINE]: 'Online',
  [HOME_PARK]: 'Home/Park',
  [CLINIC]: 'Klinik',
};

