export default {
  profilePath: '/myprofile/settings/profile',
  profileDetailPath: '/myprofile/settings/profileDetail',
  reservationsPath: '/myprofile/settings/reservation',
  packetsPath: '/myprofile/settings/packets',
  walletPath: '/myprofile/settings/wallet',
  favoritesPath: '/myprofile/settings/favorites',
  messagePath: '/myprofile/settings/message',
  facilityPath: '/myprofile/settings/facility',
  activityPath: '/myprofile/settings/activity',
  trainersPath: '/myprofile/settings/trainers',
  branchPath: '/myprofile/settings/branch',
  servicePath: '/myprofile/settings/service',
  sessiontypePath: '/myprofile/settings/location',
  pricePath: '/myprofile/settings/price',
  specialtiesPath: '/myprofile/settings/specialties',
  blogPath: '/myprofile/settings/blog',
  galleryPath: '/myprofile/settings/gallery',
  notificationPath: '/myprofile/settings/notifications',
  proMessagesPath: '/messages',
};
