// @ts-nocheck

import elementBackground from './images/elelmen-background.png';
import vkiBackground from './images/05.png';

const DefBackground = {
  elementBackground,
  vkiBackground,
};

export default DefBackground;
