import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Title from '../../components/typography/Titles';
import PacketSlider from '../../components/sliders/PacketSlider';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
const Packet = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [activeCategory, setActiveCategory] = useState(1);
  const handleSeeMoreClick = () => {
    if (activeCategory == 3) {
      history.push('/packets?subType=dt');
    }else if(activeCategory == 4){
      history.push('/packets?subType=gym');

    }else if(activeCategory == 5){
      history.push('/packets?subType=spt');

    } else {
      history.push('/packets?type=packets');
    }
  };

  const {
    content: { data: content },
  } = useSelector((state) => state.home);
  const query = true;
  //TODO : Tablara linklemeleri yapılacak
  const groups = 'Packet';
  const link = '/packets';
  const categories = [
    {
      id: 1,
      name: t('all'),
      activeClass: activeCategory == 1 ? 'active' : '',
      //link: '#all',
    },
    {
      id: 2,
      name: t('Uc2Bir Packages'),
      activeClass: activeCategory == 2 ? 'active' : '',
      // link: '#all',
    },
    {
      id: 3,
      name: t('Dietitian Packages'),
      activeClass: activeCategory == 3 ? 'active' : '',
      // link: '#all',
    },
    {
      id: 4,
      name: t('GYM Packages'),
      activeClass: activeCategory == 4 ? 'active' : '',
      // link: '#all',
    },
    {
      id: 5,
      name: t('Trainer Packages'),
      activeClass: activeCategory == 5 ? 'active' : '',
      // link: '#all',
    },
  ];
  function dataSelector() {
    var package_pt = content?.package_pt?.map((item) => ({
      ...item,
      type: 'pt',
    }));
    var package_dt = content?.package_dt?.map((item) => ({
      ...item,
      type: 'dt',
    }));
    var package_gym = content?.package_gym?.map((item) => ({
      ...item,
      type: 'gym',
    }));
    var package_spt = content?.package_spt?.map((item) => ({
      ...item,
      type: 'spt',
    }));
    switch (activeCategory) {
      case 1:
        return package_pt?.concat(package_dt);
      case 2:
        return package_pt;
      case 3:
        return package_dt;
      case 4:
        return package_gym;
      case 5:
        return package_spt;
      default:
        break;
    }
  }
  return (
    <StyledSection className={`pt ${props.className}`}>
      <Container>
        <Title
          variant="h3"
          component="h3"
          lineDisable={false}
          letterSpacing="100"
          fontWeight="600"
        >
          {t('packages')}
        </Title>
        <Title variant="h6" component="h6" fontWeight="500">
          {t('CHOOSE THE PACKAGE THAT IS SUITABLE FOR YOU, START WORK NOW')}
        </Title>
      </Container>
      <PacketSlider
        query={query}
        data={dataSelector()}
        handleClickCategory={(id) => {
          setActiveCategory(id);
        }}
        activeCategory={activeCategory}
        groups={groups}
        categories={categories}
        link={link}
        handleSeeMoreClick={handleSeeMoreClick}
      />
    </StyledSection>
  );
};

export default Packet;

const StyledSection = styled.section`
  @media (max-width: 768px) {
    display: none;
  }
`;
