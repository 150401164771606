import React, {useState ,useEffect} from 'react';
import {Material} from 'components'
const uri = `${process.env.REACT_APP_API_URL}/regions`;
import axios from 'axios';
export default function CityDistrictSelector({onSelectCity=()=>{},onSelectDistrict=()=>{}}) {
  const [city, setCity] = useState(false);
  const [town, setTown] = useState([]);

  useEffect(() => {
    if (!city) {
      axios
        .post(uri)
        .then((res) => res.data)
        .then((data) => data.data)
        .then((data) => {
          const new_data = data.map((val) => {
            return {
              id: val.id,
              val: val.id,
              name: val.name,
            };
          });
          return setCity(new_data);
        })
        .catch((err) =>
          toast.error(err, {
            position: 'bottom-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
    }
  }, [city]);
  return (
    <>
      <Material.SimpleSelect
        required
        label={"Şehir Seç"}
        items={city}
        name="city"
        // changeValue={formData?.city || ''}
        onChange={(e) => {
          axios
            .post(uri, { city_id: e.target.value })
            .then((res) => res.data)
            .then((data) => data.data)
            .then((data) => {
              const new_data = data.map((val) => {
                return {
                  id: val.id,
                  val: val.id,
                  name: val.name,
                };
              });
              return setTown(new_data);
            });
            onSelectCity(e.target.value)
          // return setFormData({
          //   ...formData,
          //   [e.target.name]: e.target.value,
          // });
        }}
      />
      <Material.SimpleSelect
        required
        label={town ? 'İlçe Seç' : 'Önce Şehir Seç'}
        items={town ? town : []}
        name="district"
        // changeValue={formData?.district || ''}
        onChange={(e) => {
          onSelectDistrict(e.target.value)

        }}
      />
    </>
  );
}
