import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Certificate } from 'components';
import { getUserCertificate } from 'actions';

export default function ProfileCertificate({ userId }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { certificate } = useSelector((state) => state.userProfile.certificate);

  useEffect(() => {
    dispatch(getUserCertificate(userId));
  }, []);

  return certificate.length > 0 ? (
  <>
  <p style={{fontSize: "0.9rem",
  margin:"-15px 0px 5px 1px",
    textAlign: "left",
    color: "#1a1818",
    padding:" 7px 10px",
    borderRadius: "10px",
    background: "#f7f8fa",
    boxShadow: "0px 0px 0px rgb(0 0 0 / 10%)"}}> Tüm sertifikalar Üç2Bir tarafından kontrol edilip onaylanmıştır. </p>
    {certificate?.map((data, index) => 
     (
      <Certificate
        key={index}
        fileText={data.name}
        isOdd={(index + 1) % 2}
        path={data?.path}
      />
    ))}
   </>
  ) : (
    
    <div className="d-flex" style={{fontSize: "0.9rem",
    margin:"-15px 0px 5px 1px",
    fontWeight: "normal",
      textAlign: "left",
      color: "#1a1818",
      padding:" 7px 10px",
      borderRadius: "10px",
      background: "#f7f8fa",
      boxShadow: "0px 0px 0px rgb(0 0 0 / 10%)"}} >
      <strong className="mx-auto">
        {t('A certificate registered to the instructor has not been validated')}
      </strong>
    </div>
    
  
  );
}