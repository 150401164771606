/* eslint-disable react/display-name */
import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { default as NativeBanner } from '../../components/sliders/Banner';
import SearchBar from '../../components/sliders/SearchBar';
import GoogleApp from 'components/GoogleMaps';
import { searchProffesional } from 'actions';
import { SET_SEARCH_PROFESSIONAL_TYPE } from 'constants/actionTypes';
//bunları şimdilik ekliyoruz
import s1 from '../../assets/banner/n1.jpg'
import s2 from '../../assets/banner/n2.jpg';
import s3 from '../../assets/banner/dw2.jpg';
import s4 from '../../assets/blog/image-1.png';
import s5 from '../../assets/banner/slider-item-1.jpg';

import styled from 'styled-components/macro'
const Banner = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [virtual, setVirtual] = useState('pt');
  const mapData = useSelector(
    (state) => state.searchProfessional?.listInfo?.data
  );
  const handleChangeVirtual = (value) => {
    dispatch({ type: SET_SEARCH_PROFESSIONAL_TYPE, payload: value });
    setVirtual(value);
  };

  useEffect(() => {
    dispatch(
      searchProffesional({
        title: '',
        type: 'map',
        minPrice: 0,
        maxPrice: 1000,
      })
    );
  }, []);
  useLayoutEffect(() => {
    const player = document.getElementById('vd-io');
    if (player) {
      player.controls = false;
      player.playsinline = true;
      player.muted = true;
      player.setAttribute('muted', ''); // leave no stones unturned :)
      player.autoplay = true;
    }
  });
  const virtuals = {
    pt: {
      className: '',
      text: t('findTrainer'),
      component: () => {
        return (
          <>
             <StyledImage
              src={s1}
            ></StyledImage>
          </>
        );
      },
    },
    gym: {
      className: '',
      text: t('Search Gym'),
      component: () => {
        return (
          <>
            <div
              className="img"
              style={{ backgroundImage: `url(${s5})`,height:'50vh' }}
            ></div>
          </>
        );
      },
    },
    dt: {
      className: '',
      text: t('Search for a Dietitian'),
      component: () => {
        return (
          <>
            <div
              className="img"
              style={{ backgroundImage: `url(${s3})`,height:'50vh' }}
            ></div>
          </>
        );
      },
    },
    map: {
      className: 'have-map',
      text: t('Word Search'),
      component: () => {
        return (
          <div className="img">
            <GoogleApp disableMinOption data={mapData} />
          </div>
        );
      },
    },
    packets: {
      className: '',
      text: t('Search Package'),
      component: () => {
        return (
          <StyledImage  src={s2} ></StyledImage>
        );
      },
    },
    'group-lessons': {
      className: '',
      text: t('Search Group Lessons'),
      component: () => {
        return (
          <div className="img" style={{ backgroundImage: `url(${s4})`,height:'50vh' }}></div>
        );
      },
    },
  };

  const slider_settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    lazyLoad: true,
  };

  const search_bar = {
    status: true,
    className: 'search-bar',
    element: () => (
      <SearchBar
        className={search_bar.className}
        virtual={virtual}
        setVirtual={handleChangeVirtual}
        virtuals={virtuals}
      />
    ),
  };

  return (
    <NativeBanner
      settings={slider_settings}
      searchBar={search_bar}
      virtuals={virtuals}
      virtual={virtual}
    />
  );
};
const StyledImage = styled.img`

width: 100%;
height: 50vh;
object-fit:cover;
position: relative;
z-index: 0;
background-position: center center;
object-position: 5% 20%;
@media (max-width: 768px) {
  height:auto;
  width:100vw;
  background-size: contain;
  background-repeat: no-repeat;
}
`
export default Banner;
