export const USER = 1;
export const PERSONAL_TRAINER = 2;
export const WORK_PLACE = 3;
export const DIETITIAN = 4;

export const USER_KEYS = {
  [USER]: 'st',
  [PERSONAL_TRAINER]: 'pt',
  [WORK_PLACE]: 'bs',
  [DIETITIAN]: 'dt',
};
