/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Main from '../../components/Main';
import Banner from './Banner';
import TopPromotion from './TopPromotion';
import Categories from './Categories';
import PT from './PT';
import FluidBanner from './FluidBanner';
import FluidBannerBottom from './FluidBannerBottom';
import Living from './Living';
import Packet from './Packet';
import GroupLesson from './GroupLesson';
import Dietitians from './Dietitians';
import MetaTags from 'react-meta-tags';

import ScrollToTop from '../../components/ScrollToTop';
// import VKI from './VKI';
import Blog from './Blog';
import { getHomeContent, getHomeTags } from 'actions';

//import Comments from './Comments';

const Home = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeContent());
  }, []);

  return (
    <Main>
      <MetaTags>
        <title>Üç2Bir</title>
        <meta
          name="description"
          content="Üç2bir ile sağlıklı bir yaşama ulaşmaya bir adım uzaktasın. Aradığın eğitmen, diyetisyen ve spor alanlarının hepsi burada. Başarmak için hemen başla!"
        />
        <meta property="og:title" content="Üc2Bir" />
      </MetaTags>
      <Banner />
      <Container>
        <Categories />
        {/* <TopPromotion background /> */}
        <PT />
        {/* {isAuthenticated ? '' : <FluidBanner />} */}
        <Living />
        {process.env.REACT_APP_PACKAGE_ENABLE == 'true' && <Packet />}
        {/* <GroupLesson /> */}
        <Dietitians />
        {/* <VKI /> */}
        <Blog />
        {/* <Comments /> */}
        {isAuthenticated ? '' : <FluidBannerBottom />}
        <ScrollToTop />
      </Container>
    </Main>
  );
};
const Container = styled.div`
  padding: 0 50px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
export default Home;
