import { React,  useEffect, useState } from "react";
import { useWindowScroll } from "react-use";

const ScrollToTop = () => {
    const { y: pageYOffset } = useWindowScroll();
    const [visible, setVisiblity] = useState(false);

    useEffect(() => {
        if (pageYOffset > 500) {
            setVisiblity(true);
        } else {
            setVisiblity(false);
        }
    }, [pageYOffset]);

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

    if (!visible) {
        return false;
    }

    return (
        <>
         <div
             className="scroll-to-top cursor-pointer text-center" 
             
             onClick={scrollToTop}
            
         >
            <i className='fas fa-angle-double-up'></i>
          <p className="arrowText"
          style={{
            fontSize: '10px',
            display: 'table',
            position: 'absolute',
            top: '35px',
            right:' -24px',
            color: '#00b2a9',
            borderRadius: '5px',
            width: '40px',
            background:'white',
            fontWeight: 'bold',
          }}
          >Yukarıya dön</p>   
       </div>
         
          </>
    );
};

export default ScrollToTop;