import {
    GET_PRIVATE_PACKAGE_DATA_FAILURE,
    GET_PRIVATE_PACKAGE_DATA_SUCCESS,
    GET_PRIVATE_PACKAGE_DATA_REQUEST
  } from '../constants';
  
  const initialState = {
   
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_PRIVATE_PACKAGE_DATA_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case GET_PRIVATE_PACKAGE_DATA_SUCCESS:
        return {
          ...state,
          user: action.payload,
          isLoading: false,
          isSuccess: true,
          error: null,
        };
  
      case GET_PRIVATE_PACKAGE_DATA_FAILURE:
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          error: action.payload.message,
        };
  
      
      default:
        return state;
    }
  };
  