import {
    HTTP_REQUEST,
    GET_PRIVATE_PACKAGE_DATA
  } from '../constants';
  
  export const getPrivatePackageData = (
    type,
  ) => async (dispatch) => {
    const url = `/user/package-${type}-data`;
  
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: 'GET',
        url,
        label: GET_PRIVATE_PACKAGE_DATA,
        transformData: (data) => data.data,
      },
    });
  };
  